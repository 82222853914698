import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { Alert } from "@material-ui/lab";
import { Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Nav from "../../components/preloggedin/Nav";
import * as actionCreator from "../../store/action/index";
import Sidebar from "../../components/preloggedin/Sidebar";
import { updateObjectProperty } from "../../asset/utility";
import { validateField } from "../../components/business/businessUtility";
import SmallSpinner from "../../components/common/smallSpinner/smallSpinner";

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: "2rem",
		"& label.Mui-focused": {
			color: "#359Db6",
		},
		"& .MuiInputBase-root": {
			color: "#000",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#359Db6",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "grey",
			},
			"&:hover fieldset": {
				borderColor: "#359Db6",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#359Db6",
			},
		},
	},

	input: {
		color: "grey",
	},

	inputText: {
		color: "#000",
	},

	buttonContainer: {
		background: "red",
		width: isMobile ? "40vw" : "15vw",
		fontSize: 18,
		margin: "auto",
		height: isMobile ? 50 : 48,
		borderRadius: 8,
		color: "#fff",
		background: "#075062",
		boxShadow: "none",
		"&:hover": {
			background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
			boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
		},
		"&:disabled": {
			background: "#9ACEDB",
			color: "#919191",
		},
	},
	resendOTPBtn:{
		cursor: "pointer",
		color: "#FF7300",
		"&:hover": {
			textDecoration: "underline",
			textDecorationColor: "#FF7300",
		},
	}
}));

const ForgotPassword = (props) => {
	const {
		loading,
		error,
		errorMessage,
		successMessage,
		ResetErrors,
		ForgotPassword,
		ResendOTP,
		confirmForgotPasswordOTP,
	} = props;
	const { initialMinute = 4, initialSeconds = 59 } = props;
	const [minutes, setMinutes] = useState(initialMinute);
	const [seconds, setSeconds] = useState(initialSeconds);
	const [user, setUser] = useState({
		email: "",
	});
	const [onSend, setOnSend] = useState({
		email: "",
		otp: "",
	});
	const [otpSent, setOtpSent] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const [errorObj, setErrorObj] = useState({});
	const [timer, setTimer] = useState({
		minutes: localStorage.getItem("timer")
			? JSON.parse(localStorage.getItem("timer")).minutes
			: 0,
		seconds: localStorage.getItem("timer")
			? JSON.parse(localStorage.getItem("timer")).seconds
			: 5,
	});


	const handleResend = () => {
		ResendOTP(user);
		setMinutes(initialMinute);
		setSeconds(initialSeconds);
	};

	const onInputValueChangedOtp = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setOnSend(updateObjectProperty(onSend, name, value));
	};

	const onInputValueChangedUser = (e) => {
		let name = e.target.name;
		let value = e.target.value;
		setUser(updateObjectProperty(user, name, value));
		if (name === "email") {
			setOnSend({ ...onSend, email: value });
			setOtpSent(false);
		}
		if (user.email === value) {
			setOtpSent(true);
		}
		setErrorObj(validateField(e.target.name, e.target.value, errorObj));
	};

	useEffect(() => {
		return () => {
			ResetErrors();
		};
	}, [ResetErrors]);

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});

	useEffect(() => {
		if (successMessage) setOtpSent(true);
		if (successMessage === "OTP verified") {
			if (window.location.pathname.includes("/forgotpassword")) {
				history.push(`/resetpassword/${localStorage.getItem("otp_token")}`);
			}
		}
	}, [successMessage]);

	useEffect(() => {
		if (errorMessage === "OTP already sent!") setOtpSent(true);
	}, [errorMessage]);

	return (
		<Grid container style={{ height: "100vh", background: "#fff" }}>
			<Sidebar />
			<Grid style={{ color: "black" }} item xs={12} sm={12} md={8}>
				<Grid
					container
					align="center"
					direction="column"
					style={{ height: isMobile ? "85vh" : "100vh" }}
				>
					<Nav detailOne={"Already have an account? "} detailTwo={"Sign In"} />
					<Grid container align="center" justify="center" direction="column">
						<Box
							// method="post"
							// onSubmit={handleSubmit}
							style={{
								height: isMobile ? "80vh" : "95vh",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								color: "black",
							}}
						>
							<Grid
								container
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Grid item xs={10} style={{ marginBottom: "2.5rem" }}>
									<Typography
										style={{
											fontSize: "2rem",
											textShadow: "0px 1px 2px #787A91",
										}}
									>
										Forgot Password?
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<TextField
										fullWidth
										required
										variant="outlined"
										id="email"
										name="email"
										label="Registered Email"
										placeholder="Enter your registered email here..."
										type="email"
										autoFocus
										className={classes.root}
										InputLabelProps={{
											className: classes.input,
										}}
										error={errorObj.hasOwnProperty("email")}
										InputProps={classes.inputText}
										onChange={onInputValueChangedUser}
										helperText={errorObj?.email}
									/>
								</Grid>

								{/* otp section */}

								{otpSent && (
									<>
										<Grid item xs={8}>
											<TextField
												fullWidth
												variant="outlined"
												required
												id="otp"
												type="tel"
												name="otp"
												placeholder="Enter your 6 digit OTP here..."
												label="Enter OTP"
												autoFocus
												className={classes.root}
												InputLabelProps={{
													className: classes.input,
												}}
												InputProps={classes.inputText}
												onChange={onInputValueChangedOtp}
												style={{marginBottom:"1.2rem"}}
											/>
										</Grid>

										{minutes == 0 && seconds == 0 ? (
												<Grid xs={8}>
												<Typography
												align="right"
												variant="caption"
												component="h3"
												color="primary"
												className={classes.resendOTPBtn}
												onClick={handleResend}
												style={{marginTop:"0rem"}}
												>
													Resend OTP
												</Typography>
											</Grid>

											
										) : (
											<Grid xs={8}>
												<Typography
													align="right"
													variant="caption"
													style={{ 
														// color: "#075062",
														color: "#FF7300",
														marginBottom:"1rem" }}
												>
													Resend OTP in <strong>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}{" "} </strong>
													seconds
												</Typography>
												
											</Grid>
										)}
									
									</>
								)}

								{errorMessage.length && !loading ? (
									<Grid item xs={8}>
										<Alert
											severity="error"
											icon={false}
											style={{ background: "#FCDDC4", color: "#FE8D31" }}
										>
											<strong>Error: {errorMessage}</strong>
										</Alert>
									</Grid>
								) : null}
								{successMessage.length && !loading ? (
									<Grid item xs={8}>
										<Alert
											severity="success"
											icon={false}
											style={{ backgroundColor: "#B7D2D9", color: "#12586A" }}
										>
											<strong>Success: {successMessage}</strong>
										</Alert>
									</Grid>
								) : null}

								<Grid item xs={6} style={{ marginTop: "1rem" }}>
									{otpSent ? (
										<Button
											className={classes.buttonContainer}
											// type="submit"
											variant="contained"
											disabled={onSend.otp.length === 6 ? false : true}
											onClick={() => {
												confirmForgotPasswordOTP(onSend);
											}}
										>
											{!loading ? "Verify OTP" : <SmallSpinner />}
										</Button>
									) : (
										<Button
											className={classes.buttonContainer}
											// type="submit"
											variant="contained"
											onClick={() => ForgotPassword(user.email)}
											disabled={errorObj?.email ||  !user.email}
										>
											{!loading ? "Send OTP" : <SmallSpinner />}
										</Button>
									)}
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = ({ authReducer }) => {
	return {
		loading: authReducer.loading,
		error: authReducer.error,
		errorMessage: authReducer.errorMessage,
		successMessage: authReducer.successMessage,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		ResetErrors: () => dispatch(actionCreator.ResetErrors()),
		ForgotPassword: (data) => dispatch(actionCreator.ForgotPassword(data)),
		confirmForgotPasswordOTP: (data) =>
			dispatch(actionCreator.confirmForgotPasswordOTP(data)),
		ResendOTP: (data) => dispatch(actionCreator.ResendPasswordOTP(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
