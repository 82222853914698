import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MedicalRelief from "./MedicalRelief";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  stocksContainer: {
    padding: "1rem 2rem",
    boxShadow: "-4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "0.5rem 1.2rem",
    },
  },
}));

const StaticPage = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { exchangeDirectory } = props;

  const staticPagesMethod = (data) => {
    switch (data) {
      case "medical-relief":
        return <MedicalRelief />;
      case "test7":
        return <>Test 7 Static page</>;
      default:
        return <>No Data Available</>;
    }
  };

  return (
    <Box className={classes.stocksContainer}>
      {staticPagesMethod(exchangeDirectory?.route)}
    </Box>
  );
};
export default StaticPage;
