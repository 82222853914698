import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function CheckboxLabels({ isChecked }) {
  const [state, setState] = React.useState(false);

  const handleChange = (event, value) => {
    setState(!state);
    isChecked(value);
  };

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <Checkbox
            checked={state}
            onChange={handleChange}
            name="checkBox"
            color="primary"
          />
        }
        label="I agree to abide by the terms and conditions, privacy policy and
        the oath of Principality of Cogito"
      />
    </FormGroup>
  );
}
