import React from "react";
import { makeStyles } from "@material-ui/core/styles";

//material
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cogflow: {
    borderRadius: 13,
    width: 305,
    // height: 90,
    marginTop: 24,
    background: localStorage.getItem("dark")
      ? theme.palette.background.default
      : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%) ",
    maxWidth: "100%",
  },
  cogflowContainer: {
    padding: "5px 8px 5px 9px",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    // color: "rgba(255, 255, 255, 0.7)",
    fontSize: 16,
    fontWeight: "bold",
    [theme.breakpoints.down(860)]: {
      fontSize: 14,
    },
    [theme.breakpoints.down(795)]: {
      fontSize: 12,
    },
  },
  subHeading: {
    // color: "#fff",
    fontSize: 12,
    fontWeight: "bold",
    [theme.breakpoints.down(860)]: {
      fontSize: 9,
    },
  },
  percentage: {
    // color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    [theme.breakpoints.down(860)]: {
      fontSize: 11,
    },
  },
  equal: {
    color: "rgb(53, 157, 182)",
    fontSize: 35,
    fontWeight: "bold",
    [theme.breakpoints.down(860)]: {
      fontSize: 25,
    },
  },
  month: {
    color: "rgba(255, 115, 0, 0.7)",
    fontSize: 10,
    [theme.breakpoints.down(860)]: {
      fontSize: 8,
    },
  },
  priceContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  price: {
    fontSize: 36,
    fontWeight: "bold",
    [theme.breakpoints.down(860)]: {
      fontSize: 26,
    },
    // color: "#DCDCDC",
  },
}));

const Conversion = (props) => {
  const classes = useStyles();

  const { euroFlow, width } = props;

  return (
    <Box className={classes.cogflow} style={{ width: width }}>
      <Box className={classes.cogflowContainer}>
        <Box className={classes.headingContainer}>
          <Typography variant="body1" className={classes.heading}>
            1 COG
          </Typography>
          <Typography variant="body1" className={classes.equal}>
            =
          </Typography>{" "}
          <Typography variant="body1" className={classes.heading}>
            1 EURO
          </Typography>
        </Box>
        <Box className={classes.headingContainer}>
          <Typography className={classes.subHeading}>
            COG Asset backing
          </Typography>
          {euroFlow[0]?.asset_backing_to_cog_circulation && (
            <Typography className={classes.percentage}>
              {euroFlow[0]?.asset_backing_to_cog_circulation} %
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Conversion;
