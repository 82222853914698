import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

//material
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
 coginfo: {
  // borderRadius: 13,
  // height: "auto",
  // marginBottom: 10,
  // background: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
 },
 coginfoContainer: {
  borderRadius: 11,
  height: "auto",
  marginBottom: 10,
  // border: "2px solid black",
  background: localStorage.getItem("dark")
   ? theme.palette.background.default
   : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
  boxShadow: localStorage.getItem("dark")
   ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
   : "-4px -4px 10px rgb(58 58 58 / 70%), 4px 4px 10px rgb(0 0 0 / 70%) ",
  // background: "linear-gradient(96.11deg, #1F1F1F 0%, #302F2F 100%)",
  // padding: "12px 17px 8px 24px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap,",
 },
 cogInfoTitle: {
  // color: "rgba(255, 255, 255, 0.7)",
  fontSize: "14",
  [theme.breakpoints.down(860)]: {
   fontSize: 11,
  },
  [theme.breakpoints.down(1250)]: {
   fontSize: 10,
  },
 },
 congInfoPrice: {
  fontSize: 14,
  color: "#359DB6",

  [theme.breakpoints.down(860)]: {
   fontSize: 10,
  },
  [theme.breakpoints.down(1250)]: {
   fontSize: 10,
  },
 },
}));

const CogInfo = (props) => {
 const classes = useStyles();
 const calc = () => {
  if (props.title === "Cash Backed Cog") {
   return props.price;
  } else return props.price;
 };

 return (
  // <Grid container spacing={1} className={classes.coginfo}>
  <>
   {/* <Grid container item className={classes.coginfoContainer}>
        <Grid item xs={12} md={6}>
          <div className="skeleton"></div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div className="skeleton"></div>
        </Grid>
      </Grid> */}

   <Grid container item xs={12} className={classes.coginfoContainer}>
    <Grid item xs={12} md={4}>
     <Typography
      variant="body1"
      align="center"
      className={classes.cogInfoTitle}
     >
      {props.title.replaceAll("Cog", "COG")}
     </Typography>
    </Grid>

    <Grid item xs={12} md={7}>
     <Typography
      variant="body1"
      align="center"
      className={classes.congInfoPrice}
     >
      {props.title === "Cash Backed Cog"
       ? calc() > 0
         ? `${+calc()?.toFixed(2)} CBC`
         : `${calc()} CBC`
       : `${calc()}`}
     </Typography>
    </Grid>
   </Grid>
  </>
  // </Grid>
 );
};

export default CogInfo;
