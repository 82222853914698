import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import BackIcon from "../../asset/images/arrow-left.png";
import { isMobile } from "react-device-detect";
import Link from "@material-ui/core/Link";
import {
 updateObject,
 updateObjectProperty,
 setWithExpiry,
 getWithExpiry,
} from "../../asset/utility";
import { useLocation } from "react-router-dom";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
 header: {
  height: "5vh",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  color: "black",
  boxSizing: "border-box",
  margin: "0 2vh",
 },
 frontHead: {
  display: "flex",
 },
 backtext: {
  fontSize: 14,
  fontWeight: "700",
  paddingLeft: 5,
  textDecoration: "none !important",
  color: "black",
 },
 backHead: {
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  [theme.breakpoints.up("768")]: {
   display: "flex",
   flexDirection: "row",
  },
  [theme.breakpoints.down("768")]: {
   display: "flex",
   flexDirection: "row",
  },
 },
 textInputField: {
  width: "auto",
  borderRadius: "20px",
 },
 headtext: {
  paddingBottom: 90,
  fontSize: 28,
  fontWeight: "900",
  textAlign: "center",
  textShadow: "0px 1px 2px #787A91",
 },
 button: {
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
 },

 buttonContainer: {
  fontSize: 18,
  margin: 20,
  width: "50%",
  height: 48,
  borderRadius: 8,
  background: "#075062",
  boxShadow: "none",
  "&:hover": {
   background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
   boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
  },
 },
}));

const Nav = ({ detailOne, detailTwo, handleBack, successMessage }) => {
 const classes = useStyles();
 const location = useLocation();
 const storedRef = getWithExpiry("ref") || null;

 return (
  <>
   <Box className={classes.header}>
    <Box className={classes.frontHead}>
     {!window.location.pathname.includes("register") ? (
      <Box style={{ display: "flex" }}>
       <Link
        style={{
         display: "flex",
         alignItems: "flex-end",
         textDecoration: "none",
        }}
        href={
         window.location.pathname.includes("forgotpassword")
          ? "/login"
          : successMessage
          ? "/signup"
          : process.env.REACT_APP_STATIC_LINK
        }
       >
        <img
         src={BackIcon}
         onClick={handleBack}
         style={{
          width: 20,
          cursor: "pointer",
         }}
        />
        <span className={classes.backtext}>Back</span>
       </Link>
      </Box>
     ) : null}
    </Box>

    <Box className={classes.backHead}>
     <Typography style={{ fontSize: 14, fontWeight: "700" }}>
      {detailOne}
     </Typography>
     {detailTwo == "Sign Up" ? (
      <Link
       href={
        location.pathname.includes("staff")
         ? "/staff/signup"
         : storedRef
         ? `/signup/${storedRef}`
         : `/signup`
       }
      >
       <Typography
        style={{
         fontSize: 14,
         fontWeight: "700",
         textDecorationLine: "underline",
         color: "#FF7300",
         paddingLeft: 5,
         cursor: "pointer",
        }}
       >
        {detailTwo}
       </Typography>
      </Link>
     ) : (
      <Link
       href={
        location.pathname.includes("staff")
         ? "https://play.google.com/store/apps/details?id=com.cogitoapp"
         : "/login"
       }
      >
       <Typography
        style={{
         fontSize: 14,
         fontWeight: "700",
         textDecorationLine: "underline",
         color: "#FF7300",
         paddingLeft: 5,
         cursor: "pointer",
        }}
       >
        {detailTwo}
       </Typography>
      </Link>
     )}
    </Box>
   </Box>
  </>
 );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
 return {
  successMessage: authReducer.successMessage,
 };
};

const mapDispatchToProps = () => {
 return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
