import {
  makeStyles,
  Typography,
  Box,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  img: {
    width: "105%",

    [theme.breakpoints.down(1280)]: {
      width: "90%",
    },
    [theme.breakpoints.down(960)]: {
      width: "85%",
    },
  },
}));

const SubscribePage = (props) => {
  const { profile, getProfile } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const SubscribeImage =
    "https://cogito.sgp1.cdn.digitaloceanspaces.com/assets/Subscribe.svg";

  const closeHandler = (e) => {
    getProfile();
    props.close();
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item container lg={2} justify="center">
            <Box>
              <img className={classes.img} src={SubscribeImage} />
            </Box>
          </Grid>

          <Grid item container lg={8} justify="center">
            <Box py={{ lg: 4 }}>
              <Typography
                variant="h4"
                style={{ fontWeight: "bold", textAlign: "center" }}
              >
                Thanks for staying with us!
              </Typography>
              <Typography
                variant="h6"
                style={{ marginTop: "1.5rem", textAlign: "center" }}
              >
                Super Exicited, You will continue recieving our monthly
                newsletter!
              </Typography>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Button
                  variant="outline"
                  onClick={closeHandler}
                  style={{
                    background: theme.palette.background.default,
                    border: "1px solid #359DB6",
                    borderRadius: "2rem",
                    height: 38,
                    width: 140,
                    margin: "1.5rem 0rem 0rem 1rem",
                  }}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    getProfile: async () => dispatch(actionCreator.getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribePage);
