import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Grid, TextField, Paper, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab/";
import * as actionCreator from "../../../store/action/index";
import callApi from "../../../asset/api";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { dialogActionsClasses } from "@mui/material";

const useStyles = makeStyles({
  root: {
    // marginTop: "3%",
    marginBottom: "3%",
  },
});

const AddresInput = (props) => {
  const {
    notify,
    getData,
    defaultCountry,
    defaultState,
    defaultCity,
    defaultPincode,
    country,
    state,
    city,
    postcode,
    disableField,
  } = props;

  const [countries, setCountries] = useState();
  const [countriesId, setCountriesId] = useState();
  const [selectedCountry, setSelectedCountry] = useState();

  const [states, setStates] = useState();
  const [statesId, setStatesId] = useState();
  const [selectedState, setSelectedState] = useState();

  const [cities, setCities] = useState();
  const [selectedCity, setSelectedCity] = useState();

  const [pincode, setPincode] = useState();

  const [ans, setAns] = useState({
    country: country ? country : "",
    state: state ? state : "",
    city: city ? city : "",
    pincode: postcode ? postcode : "",
  });
  const [changedData, setChangedData] = useState({});
  const classes = useStyles();

  const themed = useTheme();
  const matches = useMediaQuery(themed.breakpoints.down("sm"));

  useEffect(() => {
    callApi("getCountries", "POST", {})
      .then((res) => {
        const { status, successMessage, errorMessage, countries, error } = res;
        if (status) {
          setCountries(countries);
          let countryCode = {};
          countries.map((country) => {
            countryCode[country.name] = country.id;
          });
          setCountriesId(countryCode);
          res.successMessage = successMessage;
        } else {
          res.errorMessage = errorMessage;
        }
      })
      .catch((err) => {
        notify(err?.response?.data?.errorMessage, "error");
      });
  }, []);

  useEffect(() => {
    if (selectedCountry && countriesId && Object.keys(countriesId).length) {
      var selectedCountryId = countriesId && countriesId[selectedCountry];

      let reqBody = {
        countryId: selectedCountryId,
      };
      callApi("getStates", "POST", reqBody).then((res) => {
        const { status, successMessage, errorMessage, states, error } = res;
        if (status) {
          let tempStatesList = states;
          let x, y;
          if (selectedCountry === "United Kingdom") {
            //changing order of data
            x = tempStatesList?.slice(-1);
            y = tempStatesList?.slice(0, tempStatesList.length - 1);
            setStates([...x, ...y]);
            if (props?.statesList) props.statesList([...x, ...y]);
          } else {
            setStates(states);
            if (props?.statesList) props.statesList(states);
          }
          let statesCode = {};
          states &&
            states.map((state) => {
              statesCode[state.name] = state.id;
            });
          setStatesId(statesCode);
          res.successMessage = successMessage;
        } else {
          setStates(["Cogito"]);
        }
      });
    }
  }, [selectedCountry, countriesId]);

  useEffect(() => {
    if (
      selectedCountry &&
      selectedState &&
      countriesId &&
      Object.keys(countriesId).length &&
      statesId &&
      Object.keys(statesId).length
    ) {
      var selectedCountryId = countriesId && countriesId[selectedCountry];
      var selectedStateId = statesId && statesId[selectedState];

      let reqBody = {
        countryId: selectedCountryId,
        stateId: selectedStateId,
      };

      callApi("getCities", "POST", reqBody).then((res) => {
        const { status, successMessage, errorMessage, cities, error } = res;
        if (status) {
          setCities(cities);
          if (props?.citiesList) props.citiesList(cities);
          res.successMessage = successMessage;
        } else {
          setStates(["Cogito"]);
        }
      });
    }
  }, [selectedState, statesId]);

  useEffect(() => {
    if (props?.citiesList) props.citiesList(cities);
  }, [cities]);
  useEffect(() => {
    if (props?.statesList) props.statesList(states);
  }, [states]);

  const handleChange = (name, value) => {
    if (name === "country") {
      setSelectedCountry(value);
      setSelectedState("");
      setSelectedCity("");
      setPincode(null);
      setAns({ ...ans, country: value });
      setChangedData({ ...changedData, country: value });
    } else if (name === "state") {
      setSelectedState(value);
      setSelectedCity("");
      setAns({ ...ans, state: value });
      setChangedData({ ...changedData, state: value });
    } else if (name === "city") {
      setSelectedCity(value);
      setAns({ ...ans, city: value });
      setChangedData({ ...changedData, city: value });
    } else if (name === "pincode") {
      setPincode(value);
      setAns({ ...ans, pincode: value });
      setChangedData({ ...changedData, pincode: value });
    }
  };

  //sending data to parent component
  useEffect(() => {
    // only changed Data
    if (props.getChangedData) {
      props.getChangedData(changedData);
    }

    // both changed and unchanged Data
    if (props.getData) {
      props.getData(ans);
    }
    if (props.dataToParent) {
      props.dataToParent(ans);
    }
  }, [ans]);

  // setting previously saved data on mount
  useEffect(() => {
    setSelectedCountry(country);
    setSelectedCity(city);
    setSelectedState(state);
    setPincode(postcode);
  }, []);

  return (
    <>
      {countries ? (
        <>
          <Grid
            item
            className={classes.root}
            container
            direction={matches ? "column" : "row"}
            xs={12}
            justifyContent="space-between"
          >
            <Grid
              style={{ marginBottom: matches ? "1rem" : null }}
              item
              xs={matches ? 12 : 5}
            >
              <Autocomplete
                disabled={disableField ? disableField : false}
                // defaultValue={props.country}
                value={selectedCountry || ""}
                id="country"
                name="country"
                onChange={(e, value) => handleChange("country", value)}
                options={
                  countries ? countries.map((country) => country.name) : []
                }
                noOptionsText={"No country"}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: window.location.pathname.includes(
                        "staff"
                      )
                        ? localStorage.getItem("dark")
                          ? "#fff"
                          : "#212121"
                        : props.location === "register"
                        ? "#fff"
                        : localStorage.getItem("dark")
                        ? "#fff"
                        : "#212121",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Country"
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={matches ? 12 : 5}>
              <Autocomplete
                disabled={disableField ? disableField : false}
                // defaultValue={props.state}
                value={selectedState || ""}
                id="state"
                options={
                  states
                    ? states.map((state) => state.name)
                    : props.renderStatesList
                    ? props.renderStatesList.map((state) => state.name)
                    : []
                }
                onChange={(e, value) => handleChange("state", value)}
                noOptionsText={"Select country"}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: window.location.pathname.includes(
                        "staff"
                      )
                        ? localStorage.getItem("dark")
                          ? "#fff"
                          : "#212121"
                        : props.location === "register"
                        ? "#fff"
                        : localStorage.getItem("dark")
                        ? "#fff"
                        : "#212121",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="State"
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            className={classes.root}
            container
            direction={matches ? "column" : "row"}
            xs={12}
            justifyContent="space-between"
          >
            <Grid
              style={{ marginBottom: matches ? "1rem" : null }}
              item
              xs={matches ? 12 : 5}
            >
              <Autocomplete
                disabled={disableField ? disableField : false}
                value={selectedCity || ""}
                id="city"
                options={
                  cities
                    ? cities.map((city) => city.name)
                    : props.renderCitiesList
                    ? props.renderCitiesList.map((city) => city.name)
                    : []
                }
                onChange={(e, value) => handleChange("city", value)}
                noOptionsText={"select state"}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: window.location.pathname.includes(
                        "staff"
                      )
                        ? localStorage.getItem("dark")
                          ? "#fff"
                          : "#212121"
                        : props.location === "register"
                        ? "#fff"
                        : localStorage.getItem("dark")
                        ? "#fff"
                        : "#212121",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="City" required={props?.location === 'businessActivity' ? true : false}/>
                )}
              />
            </Grid>
            <Grid item xs={matches ? 12 : 5} style={{ marginBottom: "0.5rem" }}>
              <TextField
                required={props?.location === 'businessActivity' ? true : false}
                fullWidth
                disabled={disableField ? disableField : false}
                // defaultValue={props.pincode}
                value={pincode || ""}
                label="Postcode"
                onChange={(e, value) => {
                  if ((e.target.value || "").length <= 8) {
                    handleChange("pincode", e.target.value);
                  }
                }}
                type="text"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddresInput);
