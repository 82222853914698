import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Sidebar from "../components/preloggedin/Sidebar";
import StaffLinearStepper from "../components/staffRegister/staffLinearStepper";
import LinkExpiredPage from "../components/Signup/linkExpiredPage";
import * as actionCreator from "../store/action/index";
import Spinner from "../components/common/Spinner/Spinner";
import callApi from "../asset/api";

const theme = createTheme();

const Register = (props) => {
	const { regUser, successMessage, errorMessage, notify } = props;
	const [isEmailVerified, setEmailVerified] = useState(false);
	const [loading, setLoading] = useState(true);
	const [stepperInfo, setStepperInfo] = useState({});
	const token = useParams().token;
	const [staffRegToken, setStaffRegToken] = useState("");
	const [errMsg, setErrMsg] = useState(null);

	const verifyStaffEmail = (token) => {
		const reqBody = { token: token };
		setStaffRegToken(token);

		callApi("VerifyStaffEmail", "POST", reqBody)
			.then((res) => {
				const { error, errorMessage, successMessage, status, token } = res;
				if (status) {
					setLoading(false);
					setStaffRegToken(token);
					notify(successMessage, "success");
					setEmailVerified(true);
				} else {
					setLoading(false);
					setErrMsg(errorMessage);
					// notify(errorMessage, "error");
				}
			})
			.catch((err) => {
				setLoading(false);
				setErrMsg(err?.response?.data?.errorMessage);
				notify(err?.response?.data?.errorMessage, "error");
			});
	};

	const handleStepperData = (info) => {
		setStepperInfo(info);
	};

	useEffect(() => {
		if (token) {
			verifyStaffEmail(token);
		}
	}, []);

	useEffect(() => {
		if (successMessage || errorMessage) {
			setLoading(false);
		}
	}, [successMessage, errorMessage]);

	return (
		<>
			<ThemeProvider theme={theme}>
				<Grid container style={{ height: "100vh", background: "#fff" }}>
					<Sidebar />
					{!loading ? (
						isEmailVerified ? (
							<StaffLinearStepper token={staffRegToken} />
						) : (
							<LinkExpiredPage errorMsg={errMsg} />
						)
					) : (
						<Spinner />
					)}
				</Grid>
			</ThemeProvider>
		</>
	);
};

const mapStateToProps = ({ authReducer }) => {
	return {
		regUser: authReducer.regUser,
		successMessage: authReducer.successMessage,
		errorMessage: authReducer.errorMessage,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		notify: (message, varient) =>
			dispatch(actionCreator.notify(message, varient)),
		// staffRegister: () => dispatch(actionCreator.staffRegister()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
