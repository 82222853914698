import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NoOrders from "../../../asset/images/stocks/Rectangle.gif";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    margin: "2%",
  },
  tableContainer: {
    flex: 1,
    minWidth: 300,
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    padding: "10px",
    borderRadius: "11px",
    border: "1px solid #545454",
    marginBottom: "12px",
  },
  table: {
    width: "99%",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8rem",
    },
  },
  tableleData: {
    color: "#C1C1C1",
    textAlign: "center",
  },
}));

const StockOrderBook = ({ orderBook, currencyType }) => {
  const classes = useStyles();
  const topBuyOrders = orderBook?.buyOrder?.slice(0, 5);
  const topSellOrders = orderBook?.sellOrder?.slice(0, 5);
  return (
    <Box className={classes.root}>
      <div className={classes.tableContainer}>
        <Typography
          variant="h6"
          className={classes.tableleData}
          style={{ color: "#C49C39" }}
        >
          Buy{" "}
          <span style={{ color: "#fff", fontSize: "0.8rem" }}>
            ({currencyType})
          </span>
        </Typography>
        <Table className={classes.table}>
          {topBuyOrders?.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableleData}>Price </TableCell>
                  <TableCell className={classes.tableleData}>Volume</TableCell>
                  {/* <TableCell>Total (Shares)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {topBuyOrders.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableleData}>
                      € {item.price}
                    </TableCell>
                    <TableCell className={classes.tableleData}>
                      {item.quantity}
                    </TableCell>
                    {/* <TableCell>{item.shares}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={NoOrders} width={"80%"} />
              <Typography
                style={{
                  color: "#C1C1C1",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                The Buy order book is currently empty. No orders are available
                at the moment.
              </Typography>
            </Box>
          )}
        </Table>
      </div>
      <div className={classes.tableContainer}>
        <Typography
          variant="h6"
          className={classes.tableleData}
          style={{ color: "#1A8158" }}
        >
          Sell
          <span style={{ color: "#fff", fontSize: "0.8rem" }}>
            &nbsp;({currencyType})
          </span>
        </Typography>
        <Table className={classes.table}>
          {topSellOrders?.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableleData}>Price </TableCell>
                  <TableCell className={classes.tableleData}>Volume</TableCell>
                  {/* <TableCell>Total (Shares)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {topSellOrders.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableleData}>
                      € {item.price}
                    </TableCell>
                    <TableCell className={classes.tableleData}>
                      {item.quantity}
                    </TableCell>
                    {/* <TableCell>{item.shares}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <img src={NoOrders} width={"80%"} />
              <Typography
                style={{
                  color: "#C1C1C1",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                The Sell order book is currently empty. No orders are available
                at the moment.
              </Typography>
            </Box>
          )}
        </Table>
      </div>
    </Box>
  );
};

export default StockOrderBook;
