const getTransactionType = (type) => {
  switch (type) {
    case "Cog Transaction":
      return "COG";
    case "Euro Transaction":
      return "Euro";
    case "Cash Backed Cog":
      return "CBC";
    default:
      return "in your account";
  }
};

export const paymentReceived = (data) => {
  return `Received ${(data?.data?.amount || 0).toFixed(3)} ${getTransactionType(
    data?.data?.account_type
  )} ${data?.data?.fromUser ? "from " + data?.data?.fromUser?.firstName : ""} ${
    data?.data?.fromUser ? data?.data?.fromUser?.lastName : ""
  }`;
};

export const notificationMessage1 = (data) => {
  return `${(data?.data?.amount || 0).toFixed(3)} ${getTransactionType(
    data?.data?.account_type
  )} `;
};
export const notificationMessage2 = (data) => {
  return ` ${data?.data?.fromUser?.firstName} ${data?.data?.fromUser?.lastName}`;
};
