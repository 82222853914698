import axios from "axios";
import { getWithExpiry } from "./utility";
import fetch from "isomorphic-fetch";
import { notify } from "../store/action/notifyAction";
import * as Sentry from "@sentry/react";

let API_URL = process.env.REACT_APP_API_LINK;

export default function callApi(
	endpoint,
	method = "get",
	body,
	contentType = "application/json",
	isErrorSuppressed = false
) {
	let token = getWithExpiry("token") ? getWithExpiry("token") : "";

	let headers = {};
	headers["content-type"] = contentType;
	if (token && token !== "") {
	
		headers.token = `${token}`;
	}
	return (
		fetch(`${API_URL}/${endpoint}`, {
			headers: headers,
			method,
			body: JSON.stringify(body),
		})
			.then((response) => {
				return response.json();
			})
			.catch((err) => {
				return err;
			})
	);
}

// not using
export function callApi_multipart(
	endpoint,
	method = "get",
	body,
	isErrorSuppressed = false
) {
	let token = getWithExpiry("token") ? getWithExpiry("token") : "";

	let headers = {};
	headers["content-type"] = "multipart/form-data";
	if (token && token !== "") {
		headers.token = `${token}`;
	}
	return (
		fetch(`${API_URL}/${endpoint}`, {
			headers: headers,
			method,
			body: body,
		})
			.then((response) => {
				return response.json();
			})
			.catch((err) => {
				return err;
			})
	);
}

//without authcheck
export const API = axios.create({
	baseURL: process.env.REACT_APP_API_LINK,
	headers: {
		"Content-Type": "application/json",
	},
});

// for authcheck
// export const API_token = axios.create({
// 	baseURL: process.env.REACT_APP_API_LINK,
// 	headers: {
// 		"Content-Type": "application/json",
// 		token: token,
// 	},
// });
