import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { useFormik } from "formik";
import * as yup from "yup";

import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Nav from "../../components/preloggedin/Nav";
import * as actionCreator from "../../store/action/index";
import Sidebar from "../../components/preloggedin/Sidebar";

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const validationSchema = yup.object({
	password: yup
		.string()
		.matches(lowercaseRegex, "one lowercase required!")
		.matches(uppercaseRegex, "one uppercase required!")
		.matches(numericRegex, "one number required!")
		.min(8, "Password should be of minimum 8 characters")
		.required("Password is required!"),
	re_password: yup
		.string()
		.oneOf([yup.ref("password")], "Password must be the same!")
		.required("Confirm Password is required!"),
});

const useStyles = makeStyles((theme) => ({
	root: {
		"& label.Mui-focused": {
			color: "#359Db6",
		},
		"& .MuiInputBase-root": {
			color: "#000",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "#359Db6",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: "grey",
			},
			"&:hover fieldset": {
				borderColor: "#359Db6",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#359Db6",
			},
		},
	},

	input: {
		color: "grey",
	},

	inputText: {
		color: "#000",
	},

	buttonContainer: {
		background: "red",
		width: isMobile ? "40vw" : "20vw",
		fontSize: 18,
		margin: "auto",
		height: 48,
		borderRadius: 8,
		color: "#fff",
		background: "#075062",
		boxShadow: "none",
		"&:hover": {
			background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
			boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
		},
	},
}));

const UpdatePassword = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowConfirmPassword = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const [user, setUser] = useState({
		password: "",
		re_password: "",
	});

	const [token, setToken] = useState(useParams().token || null);
	const {
		history,
		loading,
		error,
		errorMessage,
		successMessage,
		ResetErrors,
		ResetPassword,
	} = props;

	const formik = useFormik({
		initialValues: {
			password: "",
			re_password: "",
		},
		validationSchema: validationSchema,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: async (values) => {
			await ResetPassword({
				token: token,
				password: user.password,
			});
			localStorage.clear();
			history.push("/login");
		},
	});

	useEffect(() => {
		setUser({ ...user, password: formik.values.password });
	}, [formik.values.password]);

	useEffect(() => {
		setUser({ ...user, re_password: formik.values.re_password });
	}, [formik.values.re_password]);

	const classes = useStyles();

	return (
		<Grid container style={{ height: "100vh", background: "#fff" }}>
			<Sidebar />
			<Grid style={{ color: "black" }} item xs={12} sm={7} md={8}>
				<Grid
					container
					align="center"
					direction="column"
					style={{ height: isMobile ? "85vh" : "100vh" }}
				>
					<Nav detailOne={"Already have an account? "} detailTwo={"Sign In"} />
					<Grid container align="center" justify="center" direction="column">
						<form
							method="post"
							onSubmit={formik.handleSubmit}
							style={{
								height: isMobile ? "80vh" : "95vh",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "column",
								color: "black",
							}}
						>
							<Grid
								container
								spacing={0}
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<Grid item xs={10} style={{ marginBottom: "4vh" }}>
									<Typography
										style={{
											fontSize: "2rem",
											textShadow: "0px 1px 2px #787A91",
										}}
									>
										Update Password
									</Typography>
								</Grid>
								<Grid item xs={10} style={{ marginBottom: "3vh" }}>
									<TextField
										fullWidth
										variant="outlined"
										label="New Password"
										id="password"
										name="password"
										type={showPassword ? "text" : "password"}
										value={formik.values.password}
										onChange={formik.handleChange}
										className={classes.root}
										InputLabelProps={{
											className: classes.input,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														edge="end"
													>
														{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
										error={formik.touched.password && Boolean(formik.errors.password)}
										helperText={formik.touched.password && formik.errors.password}
									/>
								</Grid>
								<Grid item xs={10} style={{ marginBottom: "3vh" }}>
									<TextField
										fullWidth
										variant="outlined"
										label="Re-Type New Password"
										id="re_password"
										name="re_password"
										type={showConfirmPassword ? "text" : "password"}
										value={formik.values.re_password}
										onChange={formik.handleChange}
										className={classes.root}
										InputLabelProps={{
											className: classes.input,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowConfirmPassword}
														edge="end"
													>
														{showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</InputAdornment>
											),
										}}
										error={
											formik.touched.re_password && Boolean(formik.errors.re_password)
										}
										helperText={formik.touched.re_password && formik.errors.re_password}
									/>
								</Grid>
								<Grid item xs={10}>
									<Button
										className={classes.buttonContainer}
										type="submit"
										variant="contained"
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

const mapStateToProps = ({ authReducer }) => {
	return {
		loading: authReducer.loading,
		error: authReducer.error,
		errorMessage: authReducer.errorMessage,
		successMessage: authReducer.successMessage,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		ResetErrors: () => dispatch(actionCreator.ResetErrors()),
		ResetPassword: (data) => dispatch(actionCreator.ResetPassword(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePassword);
