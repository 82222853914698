import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

const Graph = ({ data, svgWidth, svgHeight, margin }) => {
	const svgRef = useRef();

	useEffect(() => {
		// Clear existing svg content
		d3.select(svgRef.current).selectAll("*").remove();

		// Set up dimensions
		const width = svgWidth - margin.left - margin.right;
		const height = svgHeight - margin.top - margin.bottom;

		const svg = d3
			.select(svgRef.current)
			.attr("width", svgWidth)
			.attr("height", svgHeight)
			.append("g")
			.attr("transform", `translate(${margin.left}, ${margin.top})`);

		// Extracting min and max dates from data
		const minDate = d3.min(data, (d) => d.date);
		const maxDate = d3.max(data, (d) => d.date);

		// Set up scales
		const xScale = d3.scaleTime().domain([minDate, maxDate]).range([0, width]);

		const yScale = d3
			.scaleLinear()
			.domain([0, d3.max(data, (d) => d.value)])
			.range([height, 0]);

		// Draw line
		const line = d3
			.line()
			.x((d) => xScale(d.date))
			.y((d) => yScale(d.value));

		svg
			.append("path")
			.datum(data)
			.attr("fill", "none")
			.attr("stroke", "steelblue")
			.attr("stroke-width", 2)
			.attr("d", line);
	}, [data, svgHeight, svgWidth, margin]);

	return <svg ref={svgRef}></svg>;
};

Graph.defaultProps = {
	margin: { top: 20, right: 20, bottom: 30, left: 30 }, 
};

export default Graph;
