import React from 'react';
import Pagination from '@material-ui/lab/Pagination';

const PaginationComponent = props => {
  const { page, pageCount, onChange } = props;
  return (
    <>
      <Pagination
        count={pageCount}
        page={page}
        siblingCount={0}
        boundaryCount={1}
        onChange={onChange}
        variant="outlined"
        shape="rounded"
        hidePrevButton={false}
        hideNextButton={false}
      />
    </>
  );
};
PaginationComponent.defaultProps = {
  onChange: () => {},
  page: 1,
  pageCount: 0,
};
export default PaginationComponent;
