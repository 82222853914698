import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";

const PdfViwer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <Grid item container xs={12} justify="space-around">
        <Grid
          item
          container
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              onClick={prevPage}
              style={{ backgroundColor: "#359DB6" }}
            >
              Prev
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >{`${pageNumber} of ${numPages}`}</Grid>

          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              alignSelf="right"
              variant="outlined"
              onClick={nextPage}
              style={{ backgroundColor: "#359DB6" }}
            >
              Next
            </Button>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            overflow: isMobile && "scroll",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Document file={props.file} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              height={
                isMobile ? window.innerHeight * 0.6 : window.innerHeight * 0.9
              }
              pageNumber={pageNumber}
            />
          </Document>
        </Grid>
      </Grid>
    </>
  );
};

export default PdfViwer;
