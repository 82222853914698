import React from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";

import ReportProblemRoundedIcon from "@material-ui/icons/ReportProblemRounded";
import LinkRoundedIcon from "@material-ui/icons/LinkRounded";
import { Typography } from "@material-ui/core";

const LinkExpired = (props) => {
	const { errorMessage, errMsg } = props;
	return (
		<>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<LinkRoundedIcon
					style={{
						fontSize: isMobile ? "10vh" : "15vh",
						marginTop: "3vw",
						transform: "rotate(-45deg)",
						color: "#FF7300",
					}}
				/>
				<ReportProblemRoundedIcon
					style={{
						color: "#FF7300",
						fontSize: isMobile ? "10vh" : "15vh",
						margin: "3vw 0vw 2vw -2vw",
					}}
				/>
			</div>
			<Typography
				variant={isMobile ? "h5" : "h4"}
				align="center"
				style={{ marginBottom: isMobile ? "10vh" : "14vh", fontWeight: "bold" }}
			>
				<div>
					{window.location.pathname.includes("/staff")
						? `Oops, ${errMsg}`
						: `Oops, ${errorMessage}`}
				</div>
			</Typography>
		</>
	);
};

const mapStateToProps = ({ authReducer }) => {
	return { errorMessage: authReducer.errorMessage };
};

export default connect(mapStateToProps)(LinkExpired);
