import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import callApi from "../asset/api";
import * as actionCreator from "../store/action/index";
import email_verified from "../asset/images/email_verified.svg";
import Logo from "../asset/images/Header/logo_dark.svg";

import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		marginTop: "5px",
		marginLeft: "60px",
		height: "14vh",
		[theme.breakpoints.down(960)]: {
			marginLeft: "20px",
		},
	},
	logo: {
		width: "80px",
		height: "80px",
	},
	container: {
		height: "50vh",
		width: "auto",
		[theme.breakpoints.down(600)]: {
			height: "40vh",
		},
	},
	image: {
		height: "90%",
		width: "auto",
		objectFit: "contain",
		marginTop: "30px",
		[theme.breakpoints.down(650)]: {
			height: "80%",
		},
		[theme.breakpoints.down(400)]: {
			height: "70%",
		},
	},
	parentContainer: {
		height: "fit-content",
		padding: theme.spacing(2),
		marginTop: "4vh",
		boxShadow: localStorage.getItem("dark")
			? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
			: "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
	},
	buttonContainer: {
		marginBottom: "30px",
		marginTop: "10px",
		background: "red",
		fontSize: 18,
		margin: "auto",
		height: 48,
		borderRadius: 8,
		color: "#fff",
		background: "#075062",
		boxShadow: "none",
		"&:hover": {
			background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
			boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
		},
	},
}));

const ResetEmail = (props) => {
	const { notify, isLoggedIn } = props;
	const [emailVerified, setEmailVerified] = useState(false);

	const classes = useStyles();
	const { token } = useParams();

	useEffect(() => {
		if (token) {
			let reqBody = { token: token };
			// API.post(
			// 	"/verifyuseremail",
			// 	{ token: token },
			// 	{
			// 		headers: {},
			// 		withCredentials: false,
			// 	}
			// );
			callApi("verifyuseremail", "POST", reqBody)
				.then((res) => {
					const { status, errorMessage, successMessage, error } = res;
					if (status) {
						setEmailVerified(true);
						notify(successMessage, "success");
					} else {
						notify(errorMessage, "error");
					}
				})
				.catch((err) => {
					notify(err?.response?.data?.errorMessage, "error");
				});
		}
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			setTimeout(() => {
				window.location.pathname = "/profile";
			}, 5000);
		} else {
			if (emailVerified) {
				setTimeout(() => {
					window.location.pathname = "/login";
				}, 5000);
			}
		}
	});

	return (
		<>
			{!isLoggedIn && (
				<Grid
					item
					container
					spacing={2}
					xs={6}
					justifyContent="flex-start"
					alignItems="center"
					className={classes.headerContainer}
				>
					<img src={Logo} alt="reset_email" className={classes.logo} />
				</Grid>
			)}

			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.parentContainer}
			>
				<Grid
					item
					container
					spacing={2}
					xs={12}
					justifyContent="center"
					className={classes.container}
				>
					<img
						src={email_verified}
						alt="reset_email"
						className={classes.image}
					/>
				</Grid>
				<Grid item container xs={12} alignItems="center" direction="column">
					{
						<Typography variant="h5" style={{ marginTop: "40px" }}>
							{emailVerified
								? "Your Email has been changed successfully!"
								: "Your Email modification is unsuccessful!"}
						</Typography>
					}
					{isLoggedIn ? (
						<Typography variant="body1" style={{ marginBottom: "40px" }}>
							You will be automatically redirected to profile page...
						</Typography>
					) : (
						<Typography variant="body1" style={{ marginBottom: "40px" }}>
							You will be automatically redirected to login page...
						</Typography>
					)}
				</Grid>
			</Grid>
		</>
	);
};

const mapStateToProps = ({ authReducer, userReducer }) => {
	return {
		isLoggedIn: authReducer.isLoggedIn,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		notify: (message, varient) =>
			dispatch(actionCreator.notify(message, varient)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetEmail);
