import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// actions
import * as actionCreator from "../../store/action/index";

//components
import ThemeButton from "../common/button/index";

//logos
import logolight from "../../asset/images/Header/logo-light.svg";
import logodark from "../../asset/images/Header/logo_dark.svg";

//material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import Avatar from "@material-ui/core/Avatar";
import {
  IconButton,
  Button,
  Switch,
  Tooltip,
  Typography,
  List,
  Menu,
  MenuItem,
  Divider,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";

//css
import "../../components/leftSidebar/switch.css";
import { messageService } from "../../sockets/messageService";

import { Notifications } from "@material-ui/icons";
import { matches } from "@testing-library/jest-dom/dist/utils";
import { Badge, Box } from "@mui/material";
import moment from "moment/moment";
import NotificationBox from "./NotificationBox/NotificationBox";
import NotificationModal from "./NotificationBox/NotificationModal/NotificationModal";
import Banner from "../banner/Banner";

function SwitchThumb(props) {
  return (
    <div className={`switch ${props.isChecked ? "checked" : "unchecked"}`}>
      {props.isChecked ? <NightsStayIcon /> : <WbSunnyIcon />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  paper_container: {
    background: localStorage.getItem("dark") === "false" ? "#fff" : "#1a1a1a",
  },
  switchContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    // [theme.breakpoints.down("sm")]: {
    //   justifyContent: "space-between",
    // },
  },
  distrubution: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    height: "14vh",
  },

  hamIcon: {
    color: "inherit",
    display: "none",
    [theme.breakpoints.down(992)]: {
      display: "block",
    },
    marginBottom: "1rem",
  },
  banner: {
    [theme.breakpoints.down(990)]: {
      display: "none",
    },
  },

  root: {
    height: "45px",
    width: "70px",
    marginTop: "-3px",
  },
  track: {
    borderRadius: "30px",
    color: "#cfd7dc",
    backgroundColor: "#cfd7dc",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  thumb: {},
  switchBase: {
    "&$checked": {
      transform: "translateX(25px)",
      "& + $track": {
        backgroundColor: "#cfd7dc",
      },
    },
    "&$checked:hover": {
      backgroundColor: "#89d32930",
    },
    "&$focusVisible $thumb": {
      color: "#cfd7dc",
      border: "6px solid #fff",
    },
  },
  checked: {},
  icon: {
    paddingRight: "5px",
  },
  menuItem: {
    color: "#359DB6",
  },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    // backgroundColor: theme.palette.common.black,
    background: localStorage.getItem("dark") === "false" ? "#fff" : "#434342",
    color: "#359DB6",
    fontSize: 14,
    padding: theme.spacing(1),
  },
  notificationBox: {
    position: "absolute",
    backgroundColor:
      localStorage.getItem("dark") === "false" ? "#fff" : "#222222",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: "15px",
    minWidth: "100px",
    width: "400px",
    top: "50px",
    zIndex: 900,
    textAlign: "left",
    padding: "1rem",
    right: -70,
    "@global": {
      "*::-webkit-scrollbar": {
        width: "0.2em",
      },
    },
  },
  badgeStyle: {
    backgroundColor: "#FF7300",
  },
}));

const StyledTooltip = (props) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        arrow: classes.arrow,
        tooltip: classes.tooltip,
      }}
      {...props}
    />
  );
};

// export default StyledTooltip;

const Header = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const {
    logout,
    leftSideVisible,
    showLeft,
    loading,
    darkMode,
    changeTheme,
    profile,
    notification,
    markNotificationsRead,
  } = props;
  const [switchOn, setSwitchOn] = useState(darkMode);

  const [menu, setMenu] = useState(false);
  const [receivedNotification, setReceivedNotification] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [allNotifications, setAllNotifications] = useState(null);
  const [openNotificaitons, setOpenNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(null);
  const [newNotification, setNewNotification] = useState(false);
  const handleClick = () => {
    // leftSideVisible(!showLeft);
    leftSideVisible(!showLeft);
  };

  // const menuOpen = Boolean(anchorEl);

  // const handleMenu = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleLogout = () => {
    logout();
  };

  const handleChange = () => {
    setSwitchOn((prev) => !prev);
    changeTheme(!switchOn);
  };

  const handleProfile = () => {
    window.location.pathname = "/profile";
  };

  let today = moment(new Date()).format("DD-MM-YYYY");
  useEffect(() => {
    if (notification?.length) {
      let temp = [];
      let count = 0;

      notification.map((notificationItem) => {
        temp.push(notificationItem);
        if (notificationItem.is_read == false) {
          count++;
          setUnreadNotifications(count);
          setNewNotification(true);
        }
      });

      temp.sort((a, b) => {
        return (
          new Date(b.created_at || 0).getTime() -
          new Date(a.created_at || 0).getTime()
        );
      });
      let allNotifications = [...temp];
      if (temp.length >= 15) {
        setReceivedNotification(temp.slice(0, 15));
      }
      setAllNotifications(allNotifications);
    }
  }, [notification]);
  useEffect(() => {
    let notificationSubscription = messageService
      .getMessages()
      .subscribe((message) => {
        let existingmessages = receivedNotification;
        message.created_at = new Date();
        setReceivedNotification([message, ...existingmessages]);
        setUnreadNotifications(unreadNotifications + 1);
        setNewNotification(true);
      });
    return () => {
      notificationSubscription.unsubscribe();
    };
  }, [receivedNotification]);

  const handleNotificationButton = () => {
    markNotificationsRead();
    setShowNotification(!showNotification);
    setUnreadNotifications(false);
  };

  return (
    <>
      {/* <Banner
        message={
          "We are moving KYC inhouse to improve customer data security, KYC processing may take a few more days. Thanks for your patience."
        }
        required={true}
      /> */}

      <Paper elevation={3} className={classes.paper_container}>
        <Grid container className={classes.distrubution}>
          <Grid
            item
            xs={5}
            md={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* {isMobile && ( */}
            <Grid item xs={3} sm={2} className={classes.hamIcon}>
              <IconButton onClick={handleClick}>
                {showLeft ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
            </Grid>
            {/* )} */}
            <Grid item xs={5}>
              <img
                src={theme.palette.type == "dark" ? logodark : logolight}
                onClick={() => {
                  window.location = "/home";
                }}
                width="80px"
                height="80px"
              />
            </Grid>
          </Grid>
          {/*
            <Grid item >
              <SearchBar />
            </Grid>
        */}

          <Grid
            item
            container
            xs={7}
            sm={3}
            lg={2}
            className={classes.switchContainer}
          >
            <Grid item xs={4} style={{ paddingRight: "15px" }}>
              <Switch
                icon={<SwitchThumb isChecked={false} />}
                checkedIcon={<SwitchThumb isChecked={true} />}
                checked={switchOn}
                onChange={handleChange}
                name="checkedB"
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Grid>
            <Grid item xs={4}>
              {/* <ThemeButton
              onClick={handleLogout}
              title={!loading ? "Logout" : <SmallSpinner />}
            /> */}
              <StyledTooltip title={profile?.email}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  // onClick={handleMenu}
                  onMouseEnter={() => setMenu(true)}
                  onMouseLeave={() => setMenu(false)}
                  style={{ color: "#359DB6" }}
                >
                  {profile?.profile_pic ? (
                    <Avatar
                      style={{ height: "3.5rem", width: "3.5rem" }}
                      alt="profile_pic"
                      src={
                        process.env.REACT_APP_SPACE_KEY +
                        "/" +
                        profile.profile_pic
                      }
                    />
                  ) : (
                    <AccountCircle fontSize="large" />
                  )}
                </IconButton>
              </StyledTooltip>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};
const mapStateToProps = ({ authReducer, userReducer }) => {
  return {
    loading: authReducer.loading,
    profile: userReducer.profile,
    notification: userReducer.notification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actionCreator.OnLogout()),
    markNotificationsRead: () =>
      dispatch(actionCreator.markNotificationsRead()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
