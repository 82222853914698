import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import RightSideComponentTab from "./rightsidetabcomponent";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as actionCreator from "../../store/action/index";
import { connect } from "react-redux";


const useStyles = makeStyles((theme) => ({
	skeleton: {
		width: "100%",
		height: "1.5rem",
		opacity: "0.7",
		background: localStorage.getItem("dark") && "#D1D1D1",
		[theme.breakpoints.between("sm", "lg")]: {
			height: "2.5rem",
		},
		// animation: "skeleton-loading 1s linear infinite alternate",
	},

	// "@keyframes skeleton-loading": {
	//   "0%": {
	//     background: localStorage.getItem("dark")
	//       ? "#D1D1D1"
	//       : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 20%)",
	//   },

	//   "100%": {
	//     background: localStorage.getItem("dark")
	//       ? theme.palette.background.default
	//       : "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
	//   },
	// },

	rightSidebar: {
		display: "flex",
		flexDirection: "row",
		height: "78.5vh",
		// width:"80vh",
		// [theme.breakpoints.up(1200)]: {
		// 	height: "auto",
		// },
		// overflow: "scroll",
		// overflowY: "scroll",
		//padding: theme.spacing(3),
		// maxHeight: "78.5vh",
		// height: "fit-content",
		// borderRadius: 23,
		borderTopLeftRadius: 23,
		borderBottomLeftRadius: 23,
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		boxShadow:
			"-4px -4px 10px rgba(58, 58, 58, 0.7), 4px 4px 10px rgba(0, 0, 0, 0.7)",

		[theme.breakpoints.down(960)]: {
			borderBottomLeftRadius: 23,
			height:0,
		},
	},
	escrowRightSidebar: {
		height:0,
	},
	coginfoContainer: {
		borderRadius: 11,
		height: "auto",
		minHeight: "100%",
		marginBottom: 10,
		// border: "2px solid black",
		background: localStorage.getItem("dark")
			? theme.palette.background.default
			: "linear-gradient(90deg, rgba(31,31,31,1) 0%, rgba(48,47,47,1) 100%)",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		flexWrap: "wrap,",
	},
	boxCenter: {
		display: "flex",
		justifyContent: "center",
	},
	list: {
		width: 300,
		height: "auto",
		[theme.breakpoints.down("sm")]: {
			padding: 16,
			height: "auto",
			overflow: "scroll",
		},
	},
	fullList: {
		width: "auto",
	},
	buttonDrawer: {
		position: "absolute",
		top: "50%",
		right: 0,
		zIndex: 1000,
		borderBottomLeftRadius: "50px",
		borderTopLeftRadius: "50px",
		height: "3.5rem",
		width: "5rem",
		backgroundColor: theme.palette.background.default,
		boxShadow: localStorage.getItem("dark")
			? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
			: "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
	},
	drawerPaper: {
		margin: "2rem 0 2rem 0",
		height: "92.5%",
		width: 300,
		backgroundColor: theme.palette.background.default,
		overflow: "hidden",
		borderTopLeftRadius: "1rem",
		borderBottomLeftRadius: "1rem",
		boxShadow: localStorage.getItem("dark")
			? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
			: "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
		padding: 16,
	},
}));

const RightSideTabs = (props) => {
	const {location,esWindow} = props;
	const classes = useStyles();
	const matches = useMediaQuery("(max-width:760px)");
	return (
		
			<Grid container className={esWindow ? classes.escrowRightSidebar : classes.rightSidebar}>
				<RightSideComponentTab location={location}/>
			</Grid>

	);
};

const mapStateToProps = ({ userReducer }) => {
	return {
		esWindow:userReducer.esWindow
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSideTabs);