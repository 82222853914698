import React, { useState, useEffect } from "react";
import { Formik, useFormik } from "formik";
import { useParams } from "react-router";
import { connect } from "react-redux";
import * as yup from "yup";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiPhoneNumber from "material-ui-phone-number";
import phone from "phone";

import CustomButtons from "../preloggedin/CustomButtons";
import * as actionCreator from "../../store/action/index";
import AddressInput from "../common/addressInput/index";
import ThemeButton from "../common/button/index";
import { isMobile } from "react-device-detect";
import { Typography } from "@material-ui/core";
import GoogleMaps from "../common/googleaddressmap/index";

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required!"),
  lastName: yup.string().required("Last name is required!"),
  dateOfBirth: yup
    .date()
    .max(new Date(), `Invalid Date of Birth`)
    .required("Date of Birth is required!"),
  address: yup.string().max(200, "Address should not be more than 200 words!"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "90%",
    paddingTop: theme.spacing(0),
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
    },
  },
  buttonContainer: {
    width: "100%",
    fontSize: 18,
    margin: "auto",
    height: 48,
    borderRadius: 8,
    background: "#075062",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
  },
  frm: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "fit-content",
    width: "90%",
    marginTop: "1rem",
    paddingBottom: "1rem",
    overflowY: "scroll",
    [theme.breakpoints.down(960)]: {
      marginLeft: "1rem",
    },
    [theme.breakpoints.down(720)]: {
      marginTop: "4rem",
    },
    [theme.breakpoints.down(600)]: {
      marginTop: "2rem",
    },
  },
}));

const RegisterPage = (props) => {
  const {
    activeStep,
    setActiveStep,
    confirmEmailToken,
    profile,
    kyc,
    updateUser,
    skipped,
    setSkipped,
    notify,
    userKYC
  } = props;

  const [token, setToken] = useState(useParams().token || null);
  const today = new Date().toISOString().split("T")[0]; // Get today's date

  const [phoneNum, setPhoneNumber] = useState();
  const [errorMessages, setErrorMessages] = useState({
    phoneError: "",
  });
  const [statesList, setStatesList] = useState(props.renderStatesList);
  const [citiesList, setCitiesList] = useState(props.renderCitiesList);

  const handleStatesList = (data) => {
    setStatesList(data);
  };
  const handleCitiesList = (data) => {
    setCitiesList(data);
  };

  const formik = useFormik({
    initialValues:
      !skipped && props.formData
        ? {
            firstName: props.formData.firstName,
            lastName: props.formData.lastName,
            phone: props.formData.phone,
            dateOfBirth: props.formData.dateOfBirth,
            address: props.formData.address,
            country: props.formData.country,
            state: props.formData.state,
            city: props.formData.city,
            pincode: props.formData.pincode,
          }
        : {
            firstName: "",
            lastName: "",
            phone: "",
            dateOfBirth: "",
            address: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
          },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      if (props.location == "register") {
        setSkipped(false);
      }
      if (props.location === "userInfo") {
        onProfileSave();
      }
      if (formik.values.country) {
        // if we already have country data then we send all the data
        props.loadData(values);
      } else {
        // if we dont have country data, we send only all data except address part
        const { country, state, city, pincode, ...rest } = values;
        props.loadData(rest);
      }
      props.statesList(statesList);
      props.citiesList(citiesList);
      setActiveStep(activeStep + 1);
    },
  });

  // validating token
  // useEffect(() => {
  //   if (!window.location.pathname.includes("/staff")) {
  //     if (token) confirmEmailToken({ token: token });
  //   }
  // }, [token]);

  useEffect(() => {
    // manually setting incoming data on first load
    if (props.formData && !skipped) {
      setPhoneNumber(props.formData.phone);
    }
    if (props.formData) {
      errorMessages.phoneError = "";
    }
  }, []);

  useEffect(() => {
    if (props.formData && !skipped) {
      formik.values.country = formik.values.country
        ? formik.values.country
        : props.formData.country;
      formik.values.state = props.formData.state;
      formik.values.city = props.formData.city;
      formik.values.pincode = props.formData.pincode;
    }
  }, [formik.values.country]);

  const handleSkip = (e) => {
    e.preventDefault();
    setSkipped(true);
    setActiveStep(activeStep + 1);
  };

  const handleDataFromAddress = (ans) => {
    formik.values.country = ans.country;
    formik.values.state = ans.state;
    formik.values.city = ans.city;
    formik.values.pincode = ans.pincode;
  };

  const handleDataFromAddress1 = (ans) => {
    formik.values.address = ans.address;
  };

  const handleChangedDataFromAddress = (ans) => {
    return;
  };

  const handleDataToParent = (data) => {
    props.getAddressInputData(data);
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    let result = phone(value);
    if (value === "") {
      errorMessages.phoneError = "Phone number is required ";
    } else {
      if (result.length < 1) {
        errorMessages.phoneError = "Incorrect phone number";
      } else {
        errorMessages.phoneError = "";

        //manually setting formik's phone
        formik.values.phone = result[0];
      }
    }
  };

  const onProfileSave = () => {
    const personnelData = ["firstName", "lastName", "phone", "dateOfBirth"];
    let changes = {};
    personnelData.map((item) => {
      if (formik.values[item] && formik.values[item] !== profile[item]) {
        changes[item] = formik.values[item];
      }
    });
    const addressData = ["address", "country", "state", "city", "pincode"];
    addressData.map((item) => {
      if (
        formik.values[item] &&
        formik.values[item] !== profile.address_details[item]
      ) {
        changes[`address_details.${item}`] = formik.values[item];
      }
    });
    if (Object.keys(changes).length !== 0) {
      updateUser({
        changes: changes,
      });
    } else {
      notify("No modifications added", "error");
    }
  };

  const classes = useStyles();
  return (
    <>
      <form onSubmit={formik.handleSubmit} className={classes.frm}>
        <Grid item xs={12} sm={12} md={10} elevation={0}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className={classes.root}>
              <Grid
                container
                spacing={props.spacing}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* {props.location === "register" &&
                  !window.location.pathname.includes("staff") ? (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={handleSkip}
                    >
                      Skip
                    </Button>
                  ) : null} */}
                </Grid>

                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    disabled={props.disableField}
                    variant="outlined"
                    type="text"
                    margin="normal"
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    disabled={props.disableField}
                    variant="outlined"
                    type="text"
                    margin="normal"
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <MuiPhoneNumber
                    style={{ marginTop: "1rem" }}
                    required
                    disabled={props.disableField}
                    variant="outlined"
                    name="phone"
                    label="Phone Number"
                    fullWidth
                    error={errorMessages.phoneError ? true : false}
                    helperText={errorMessages.phoneError}
                    value={phoneNum}
                    onChange={handlePhoneChange}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={5}
                  style={{ marginTop: isMobile && "1vh" }}
                >
                  <TextField
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}
                    fullWidth
                    disabled={props.disableField}
                    type="date"
                    inputProps={{ max: today }}
                    variant="outlined"
                    name="dateOfBirth"
                    value={formik.values.dateOfBirth}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dateOfBirth &&
                      Boolean(formik.errors.dateOfBirth)
                    }
                    helperText={
                      formik.touched.dateOfBirth && formik.errors.dateOfBirth
                    }
                  />
                </Grid>

                {props.location === "userInfo" && kyc?.status && userKYC?.status === 'completed' ? (
                  <Grid item xs={12}>
                    <Typography
                      align="center"
                      style={{
                        fontSize: 13,
                        fontWeight: "700",
                        color: "#359DB6",
                        paddingLeft: 5,
                      }}
                    >
                      Above details are attached to KYC and are non-editable.
                      Contact support for more info
                    </Typography>
                  </Grid>
                ) : null}

                <Grid item xs={12} style={{ marginBottom: "1rem" }}>
                  <TextField
                    style={{ marginBottom: "1rem" }}
                    fullWidth
                    disabled={false}
                    variant="outlined"
                    margin="normal"
                    type="text"
                    id="address"
                    label="Address"
                    name="address"
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                  {/* <GoogleMaps
                    defaultAddress={formik.values.address}
                    onValueChange={(value) =>
                      handleDataFromAddress1({ address: value })
                    }
                    // onValueChange={(value) => {
                    //   onUserAddressValueChanged("address_details.address",value)
                    // }}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  /> */}
                </Grid>

                <Grid item xs={12}>
                  <AddressInput
                    location={props.location}
                    disableField={props.disableField}
                    statesList={handleStatesList}
                    citiesList={handleCitiesList}
                    renderStatesList={props.renderStatesList}
                    renderCitiesList={props.renderCitiesList}
                    country={
                      props.formData && !skipped && props.formData?.country
                    }
                    state={props.formData && !skipped && props.formData?.state}
                    city={props.formData && !skipped && props.formData?.city}
                    postcode={
                      props.formData && !skipped && props.formData?.pincode
                    }
                    getData={handleDataFromAddress}
                    getChangedData={handleChangedDataFromAddress}
                    dataToParent={handleDataToParent}
                  />
                </Grid>

                {props.location === "userInfo" ? (
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    style={{
                      position: "absolute",
                      bottom: "0.1rem",
                      right: "2rem",
                      width: "100%",
                      borderRadius: 21,
                      border: 0,
                      fontFamily: "Manrope",
                      fontStyle: "normal",
                      "&:hover": {
                        background: "#62c3db",
                      },
                    }}
                  >
                    <ThemeButton
                      title="Save"
                      disabled={
                        !(
                          errorMessages.phoneError === "" &&
                          formik.values.country &&
                          formik.values.pincode
                        )
                      }
                      // onClick={onProfileSave}
                    />
                  </Grid>
                ) : null}

                {props.location === "beneficiary" ? (
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    <ThemeButton
                      title="Add"
                      width="20%"
                      onClick={props.loadData}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </div>

          {props.location === "register" ? (
            <CustomButtons
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              // allChecked={!formik.isSubmitting}
              allChecked={errorMessages.phoneError === ""}
            />
          ) : null}
        </Grid>
      </form>
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    userKYC: userReducer.userKYC,
    profile: userReducer.profile,
    kyc: userReducer.kyc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    confirmEmailToken: (data) => dispatch(actionCreator.confirmOTP(data)),
    updateUser: (data) => dispatch(actionCreator.updateUser(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
