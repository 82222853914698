import React, { useState } from "react";
import {
  Grid,
  Modal,
  Paper,
  Typography,
  Button,
  TextField,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModalHeading from "../modalHeading";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import SmallSpinner from "../smallSpinner/smallSpinner";
import ThemeButton from "../button";
import OTPInput from "react-otp-input";
import WarningPic from "../../../asset/images/Banking/WarningDeleteProfile.svg";
import LetterPic from "../../../asset/images/Banking/letter.svg";

const useStyles = makeStyles((theme) => ({
  renewKyc: {
    position: "absolute",
    padding: "0 1rem",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "35vh",
    maxWidth: "40vw",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
  },
  kycModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "80vh",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
  },
  customKycModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "80vh",
    // height: "35vh",
    // maxWidth:'40vw',
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
  },
  updateModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "fit-content",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  deleteUserModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "fit-content",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  conditionDiv: {
    margin: "3px 0 20px 0",
    maxHeight: "20vh",
    height: "20vh",
    width: "90%",
    padding: "12px",
    border: "1px solid #8F8D8D",
    borderRadius: "7px",
  },
  deleteModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "40vh",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },

  kycDismissModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "35vh",
    overflowX: "hidden",
    // background: "#1A1A1A",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
    // [theme.breakpoints.up("sm")]: {
    //   width: "50vw",
    // },
  },
  kycRevokeModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    height: "28vh",
    overflowX: "hidden",
    // background: "#1A1A1A",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    borderRadius: 10,
    // [theme.breakpoints.up("sm")]: {
    //   width: "50vw",
    // },
  },
  renewKycContainer: {
    // height: "50vh",
    overflow: "scroll",
    overflowX: "hidden",
  },
  kycModalContainer: {
    height: "50vh",
    overflow: "auto",
    overflowX: "hidden",
  },
  customKycModalContainer: {
    height: "60vh",
    [theme.breakpoints.down(400)]: {
      overflowY: "scroll",
      overflowX: "hidden",
    },
  },
  buttonModalContainer: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "10vh",
  },

  //  conditionDiv: {
  //   margin: "3px 0 20px 0",
  //   maxHeight: "14vh",
  //   height: "20vh",
  //   overflowY: "scroll",
  //   border: "1px solid #8F8D8D",
  //  },
}));

const BasicModal = (props) => {
  const {
    open,
    handleClose,
    title,
    subTitle,
    subTitleHeading,
    subTitlePara,
    infoTitle,
    parentCB,
    parentRevokeCB,
    status,
    id,
    reqBody,
    parentDeleteCB,
    parentConditionDeleteCB,
    arrIndex,
    condData,
    parentUpdateCB,
    dialogHandle,
    deleteProfile,
    deleteWarningProfile,
    confirmDeleteProfile,
    step,
    header,
    modalOpen,
    buttonLoader,
    buttonName,
    onClickFn,
    otpInputProps,
    transactionPin,
  } = props;

  const [checked, setChecked] = useState(false);
  const [data, setData] = useState({
    condition: null,
    status: "CONDITION_PENDING",
    created_at: null,
  });
  const [disabled, setDisabled] = useState(true);
  const classes = useStyles();
  const [deleting, setDeleting] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const confirmHandler = () => {
    {
      title == "Remove Member" ? parentRevokeCB(reqBody) : parentCB(status, id);
    }
  };

  function NewlineTextHeading(props) {
    const text = props.text;
    return text
      .split("\n")
      .map((str) => (
        <p style={{ paddingLeft: "1rem", fontSize: 16, fontWeight: "bold" }}>
          {str}
        </p>
      ));
  }

  function NewlineTextPara(props) {
    const text = props.text;
    return text
      .split("\n")
      .map((str) => <p style={{ paddingLeft: "1rem" }}>{str}</p>);
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const deleteHandler = () => {
    parentDeleteCB(arrIndex);
    handleClose();
  };

  const deleteConditionHandler = () => {
    setDeleting(true);
    setTimeout(() => {
      parentConditionDeleteCB(condData);
      setDeleting(false);
      handleClose();
    }, 500);
  };

  const deleteRequestHandler = () => {
    setDeleting(true);
    setTimeout(() => {
      dialogHandle();
      setDeleting(false);
      handleClose();
    }, 500);
  };

  const deleteBankAccountHandler = () => {
    setDeleting(true);
    setTimeout(() => {
      onClickFn();
      setDeleting(false);
      handleClose();
    }, 500);
  };

  const updateHandler = () => {
    parentUpdateCB(arrIndex, data);
    setDisabled(true);
  };

  const textHandleChange = (e) => {
    if (e.target.value == condData.condition) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setData({
      ...data,
      condition: e.target.value,
      created_at: condData?.created_at,
    });
  };

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  return (
    <>
      {title == "Dismiss KYC" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycDismissModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {subTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography style={{ paddingLeft: "1rem", fontSize: 14 }}>
                {infoTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                onClick={confirmHandler}
              >
                <Typography>Confirm</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Approve KYC" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12} className={classes.kycModalContainer}>
              <NewlineTextHeading text={subTitleHeading} />
              <NewlineTextPara text={subTitlePara} />
            </Grid>
            <Grid item xs={12}>
              <FormGroup row style={{ paddingLeft: "1rem" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      color="default"
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                    />
                  }
                  label="I agree to the above terms and conditions. "
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
              <Button
                disabled={!checked}
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  opacity: !checked ? 0.3 : 1,
                  height: 40,
                  width: 100,
                }}
                onClick={confirmHandler}
              >
                <Typography>Confirm</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Remove Member" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycRevokeModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {subTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                onClick={confirmHandler}
              >
                <Typography>Confirm</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Delete Condition" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycDismissModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {subTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
                id="Cancel"
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                id="Delete"
                onClick={deleteHandler}
              >
                <Typography>Delete</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Delete a Condition" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycDismissModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {subTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                onClick={deleteConditionHandler}
              >
                {deleting ? <SmallSpinner /> : <Typography>Delete</Typography>}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Delete Request" ||
        title == "Reject Request" ||
        title == "Cancel Request" ||
        title == "Cancel Payment" ||
        title == "Approve Cancellation Request" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycDismissModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {subTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography>
                  {title == "Cancel Request" || title == "Cancel Payment"
                    ? "No"
                    : "Cancel"}
                </Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                onClick={deleteRequestHandler}
              >
                {deleting ? (
                  <SmallSpinner />
                ) : (
                  <Typography>
                    {title == "Delete Request"
                      ? "Delete"
                      : title == "Approve Cancellation Request"
                      ? "Approve"
                      : title == "Reject Request"
                      ? "Reject"
                      : title == "Cancel Request" || title == "Cancel Payment"
                      ? "Yes"
                      : "Delete"}
                  </Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Update Condition" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.updateModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {subTitle}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography>
                  {title == "Cancel Request" ? "No" : "Cancel"}
                </Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                onClick={deleteRequestHandler}
              >
                {deleting ? (
                  <SmallSpinner />
                ) : (
                  <Typography>
                    {title == "Delete Request"
                      ? "Delete"
                      : title == "Approve Cancellation Request"
                      ? "Approve"
                      : title == "Reject Request"
                      ? "Reject"
                      : title == "Cancel Request"
                      ? "Yes"
                      : "Delete"}
                  </Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : header == "Delete Bank Account" ? (
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycDismissModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.header} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {title}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography> No</Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                onClick={deleteBankAccountHandler}
              >
                {deleting ? <SmallSpinner /> : <Typography>Yes</Typography>}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Update Condition" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.updateModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>

            <Box className={classes.conditionDiv}>
              <Grid
                item
                xs={12}
                style={{ height: "100%", overflowY: "scroll", outline: "none" }}
              >
                <TextField
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      border: "none",
                    },
                  }}
                  fullWidth
                  id="condData"
                  multiline
                  minRows={2}
                  variant="standard"
                  defaultValue={condData.condition}
                  onChange={(e) => textHandleChange(e)}
                />
              </Grid>
            </Box>

            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                id="Cancel"
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                disabled={disabled}
                variant="contained"
                id="Update"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                  opacity: disabled ? 0.3 : 1,
                }}
                onClick={updateHandler}
              >
                <Typography>Update</Typography>
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Add Condition" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.updateModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>

            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "70ch", height: "100%" },
              }}
              noValidate
              autoComplete="off"
              style={{
                border: "1px solid #8F8D8D",
                width: "100%",
                height: "100%",
                margin: "5% 0 3% 0",
              }}
            >
              <TextField
                type="text"
                name="condition"
                multiline
                rows={5}
                style={{ margin: "2% 0 0 1%" }}
                placeholder="  type your condition here..."
                // value={newCondition.condition}
                // onChange={(e) => singleConditionHandler(e)}
              />
            </Box>
            <Grid style={{ textAlign: "right", margin: " 0 0 4% 0" }}>
              <Button
                className={classes.addButton}
                //  onClick={addConditionHandler}
              >
                Add
              </Button>
            </Grid>

            <Box className={classes.conditionDiv}>
              <Grid container>
                <Grid
                  item
                  xs={9}
                  style={{
                    margin: "2% 0 3% 3%",
                  }}
                >
                  {/* {item.condition} */}
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    margin: "5% 0 0 1%",
                    textAlign: "center",
                  }}
                >
                  <Button
                  //  onClick={() => deleteHandler(index)}
                  >
                    <DeleteIcon
                      style={{
                        color: "#C0671E",
                        //  position: "fixed"
                      }}
                    />
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    margin: "5% 0% 0 0",
                    textAlign: "center",
                  }}
                >
                  <Button
                    //  onClick={() => editHandler(index, item)}
                    id="Edit"
                  >
                    <EditIcon
                      style={{
                        color: "#359DB6",
                        // position: "fixed"
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                style={{ backgroundColor: "#359DB6" }}
                id="ListCondition"
                //  onClick={updateEscrow}
              >
                List Condition
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title === "Renew your KYC" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.renewKyc}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12} className={classes.renewKycContainer}>
              {props.data}
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Delete Profile" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            component={Paper}
            xs={10}
            className={classes.updateModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography>
                <text style={{ fontSize: "1.2rem" }}>
                  Are you sure you want delete your account ?
                </text>
                <br />
                <br />
                <text style={{ fontSize: "0.9rem" }}>
                  This action is permanent and cannot be reverted back.
                </text>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", margin: "5% 0%" }}>
              <ThemeButton
                title="Delete"
                width="30%"
                onClick={() => deleteProfile()}
              />
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Delete Your Account" && step == "step1" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            component={Paper}
            xs={11}
            md={6}
            lg={6}
            className={classes.deleteUserModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {isImageLoading && <SmallSpinner />}
              <img
                src={WarningPic}
                alt="warning_pic"
                onLoad={handleImageLoad}
              />
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <h2>Important Information</h2>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "1.2rem",
                    display: "inline-block",
                    margin: "1% 10% 0 10%",
                  }}
                >
                  Please be advised that by opting to delete your account, it
                  will not be possible to reinstate it thereafter. Additionally,
                  all funds associated with your account, as well as any
                  affiliated accounts sponsored or defaulted to it, will be
                  permanently lost.
                </Typography>
                <br />
                <br />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center", margin: "5% 0%" }}>
              <ThemeButton
                title="Got It"
                width="30%"
                onClick={() => deleteWarningProfile()}
              />
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Delete Your Account" && step == "step2" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            component={Paper}
            xs={11}
            md={6}
            lg={6}
            className={classes.deleteUserModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>

            <Grid container justifyContent="center">
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "1.2rem",
                    display: "inline-block",
                    margin: "1% 15% 0 15%",
                  }}
                >
                  Your decision to proceed with the account deletion process has
                  been noted. To confirm this action, kindly enter your PIN.
                </Typography>
                <br />
                <br />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ paddingTop: "1rem", margin: "1% 38% 0 38%" }}
            >
              <OTPInput {...otpInputProps} />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center", margin: "5% 0%" }}>
              <ThemeButton
                title="Continue"
                width="30%"
                onClick={() => confirmDeleteProfile()}
              />
            </Grid>
          </Grid>
        </Modal>
      ) : title == "Delete Your Account" && step == "step3" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            component={Paper}
            xs={11}
            md={6}
            lg={6}
            className={classes.deleteUserModal}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", paddingTop: "0.5rem" }}
            >
              {isImageLoading && <SmallSpinner />}
              <img src={LetterPic} alt="letter_pic" onLoad={handleImageLoad} />
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography
                  variant="body1"
                  style={{
                    fontSize: "1.2rem",
                    display: "inline-block",
                    margin: "1% 10% 0 10%",
                    padding: "1rem 0",
                  }}
                >
                  We have dispatched an email to your registered account. Kindly
                  review your inbox and proceed by clicking on the confirmation
                  link provided.
                </Typography>
                <br />
                <br />
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      ) : header == "Edit Activity" ? (
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycDismissModal}
            style={{ width: "40%" }}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.header} handleClose={handleClose} />
            </Grid>
            {props.component}
          </Grid>
        </Modal>
      ) : header == "Reject Request" ||
        header == "Cancel Request" ||
        header == "Leave Request" ||
        header == "Delete Activity" ||
        header == "Remove Member" ? (
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.kycDismissModal}
            style={{ width: "40%" }}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.header} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ paddingLeft: "1rem", textAlign: "center" }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                }}
                onClick={onClickFn}
              >
                {buttonLoader ? (
                  <SmallSpinner />
                ) : (
                  <Typography>{buttonName}</Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : header == "Make Payment" ? (
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={8}
            md={4}
            sm={8}
            className={classes.kycDismissModal}
            style={{ height: "40vh" }}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.header} handleClose={handleClose} />
            </Grid>
            <Grid item container xs={12}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "1rem",
                }}
              >
                <Grid item xs={12}>
                  <Typography style={{ textAlign: "center" }} variant="h6">
                    Transaction PIN
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: "1rem" }}>
                  <OTPInput {...otpInputProps} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonModalContainer}>
              <Button
                variant="contained"
                style={{ height: 40, width: 100 }}
                onClick={handleClose}
              >
                <Typography>Cancel</Typography>
              </Button>
              <Button
                variant="contained"
                disabled={transactionPin?.length == 6 ? false : true}
                style={{
                  backgroundColor: "#075062",
                  color: "#fff",
                  marginLeft: "2rem",
                  height: 40,
                  width: 100,
                  opacity: transactionPin?.length == 6 ? 1 : 0.3,
                }}
                onClick={onClickFn}
              >
                {buttonLoader ? (
                  <SmallSpinner />
                ) : (
                  <Typography>{buttonName}</Typography>
                )}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : title === "Renew your KYC" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.renewKyc}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12} className={classes.renewKycContainer}>
              {props.data}
            </Grid>
          </Grid>
        </Modal>
      ) : title === "Fill your Cryptopia Form" ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.renewKyc}
          >
            <Grid item xs={12}>
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12} className={classes.renewKycContainer}>
              {props.data}
            </Grid>
          </Grid>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            component={Paper}
            container
            item
            xs={10}
            sm={6}
            className={classes.customKycModal}
          >
            <Grid item xs={12} alignItems="center">
              <ModalHeading title={props.title} handleClose={handleClose} />
            </Grid>
            <Grid item xs={12} className={classes.customKycModalContainer}>
              {props.data}
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default BasicModal;
