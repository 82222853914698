import React from "react";

//material
import { Button } from "@material-ui/core";
import SmallSpinner from "../smallSpinner/smallSpinner";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const CustomButton = withStyles({
 root: {
  width: "100%",
  borderRadius: 21,
  border: 0,
  color: "white",
  // fontFamily: "Manrope",
  fontStyle: "normal",
  "&:hover": {
   background: "#62c3db",
  },
 },
 label: {
  textTransform: "capitalize",
 },
 disabled: {
  background: "#8ab0b9",
  opacity: 0.5,
 },
})((props) => <Button {...props} />);

const ThemeButton = (props) => {
 return (
  <CustomButton
   type={props.title === "Save" && "submit"}
   variant="contained"
   onClick={props.onClick}
   disabled={props.disabled ? props.disabled : false}
   id={props.id ? props.id : ""}
   style={{
    background: props.background ? props.background : "#359DB6",
    border: props.border ? props.border : "",
    borderRadius: props.borderRadius ? props.borderRadius : "",
    color: props.color ? props.color : "",
    cursor: props.cursor ? props.cursor : "default",
    margin: props.margin ? props.margin : "",
    width: props.width ? props.width : "104%",
    maxWidth: props.maxWidth && props.maxWidth,
    height: props.height ? props.height : "100%",
    paddingLeft: props.paddingLeft ? props.paddingLeft : "",
    paddingRight: props.paddingRight ? props.paddingRight : "",
    cursor:'pointer'
   }}
  >
   {/* { props.title} */}
   {props.showLoader ? <SmallSpinner /> : props.title}
  </CustomButton>
 );
};

export default ThemeButton;
