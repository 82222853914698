import {
  Avatar,
  Backdrop,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  TextField,
  InputBase,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import {
  Add,
  AddCircleOutline,
  AddCircleOutlined,
  CancelOutlined,
  Remove,
} from "@material-ui/icons";
import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionCreator from "../../../store/action/index";
import OTPInput from "react-otp-input";
import { typeOf } from "react-is";
import Spinner from "../../common/Spinner/Spinner";
import Graph from "./Graph/Graph";
import LineChart from "./Graph/newGraph";
import { Select } from "@mui/material";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import BuyDetails from "./BuyDetails";
import StockOrderBook from "./OrderBook";
import {
  priceFormatter,
  formatPrice,
} from "../../../utils/numberFormater/priceFormater";
import SmallSpinner from "../../common/smallSpinner/smallSpinner";
import StockTransactionList from "./StockTransactionList";

const useStyles = makeStyles((theme) => ({
  selectedMenuItem: {
    backgroundColor: "#1B5563",
    color: "#fff",
  },

  modalContainer: {
    // height: "60vh",
    // width: "50%",
    // right: "10%",
    // marginTop: "8rem",
    background: localStorage.getItem("dark") ? "#fff" : "#000",
    boxShadow: "",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      right: "0",
    },
    boxShadow: localStorage.getItem("dark")
      ? "-2px -2px 2px 0px rgba(255,255,255,1)"
      : " -2px -2px 5px 0px rgba(0,0,0,0.75)",
  },
  createAccountContainer: {
    height: "auto",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    position: "relative",
    right: "10%",
    boxShadow: localStorage.getItem("dark")
      ? "-2px -2px 2px 0px rgba(255,255,255,1)"
      : " -2px -2px 5px 0px rgba(0,0,0,0.75)",
  },
  modalHeading: {
    display: "flex",
    backgroundColor: localStorage.getItem("dark") ? "#cdcdcd" : "#000",
    height: "10vh",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subText: {
    display: "flex",
    color: "#ACACAC",
    margin: "0",
  },
  priceBox: {
    height: "7vh",
    width: "100%",
    backgroundColor: "#C9E5EC",
    borderRadius: "50px",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "2% 6%",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      margin: "2%",
      padding: "10px 20px",
    },
  },
  quantityField: {
    width: "100%",
    marginTop: "0.5rem",
    borderRadius: "5px",
    fontWeight: "bold",
    // [`& .MuiInputBase-input`]: {
    //   textAlign: "center",
    //   // color: "#C49C39",
    // },
    // [`& .MuiFormHelperText-root`]: {
    //   backgroundColor: "#1a1a1a",
    // },
    // [`& .MuiFormHelperText-marginDense`]: {
    //   color: "red",
    //   fontSize: "0.6rem",
    //   marginTop: 0,
    // },

    [theme.breakpoints.down("xs")]: {
      width: "16vw",
    },
  },
  otpBox: {
    width: "35px !important",
    height: "35px !important",

    [theme.breakpoints.down("md")]: {
      width: "30px !important",
      height: "30px !important",
    },
  },
  Typography: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9rem",
    },
  },
  detailBox: {
    padding: "3% 8% 0%",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0 2%",
    },
  },
  CreateDetailBox: {
    padding: "8%",
    alignItems: "center",
  },
  pinField: {
    justifyContent: "center",
    alignItems: "left",
    margin: "4%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  PayButton: {
    marginTop: "1rem",
    width: "80%",
    borderRadius: "50px",
    backgroundColor: "#359DB6",
    alignSelf: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      width: "60%",
    },
  },
  createButtonGrid: {
    textAlign: "center",
    paddingTop: "4%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  CreateButton: {
    backgroundColor: "#C49C39",
    textAlign: "center",
    width: "30%",
    // margin: "6px 0px",
    borderRadius: "25px",
    fontSize: "1rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "auto",
    },
  },
  timeDurationButton: {
    backgroundColor: "tranparent",
    textAlign: "center",
    margin: "6px 0px",
    border: "1px solid #848484",
    borderRadius: "15px",
    // width: "24px !important",
    height: "40px",
    minWidth: "47px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0",
    color: "#848484",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "auto",
    },
    "&.active": {
      backgroundColor: "#359DB6",
      color: "#fff",
    },
  },
  selectedTransaction: {
    color: "#FFB476",
    borderBottom: "1px solid #FFB476",
  },
  buttonsForBuyType: {
    backgroundColor: "transparent",
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    border: "none",
    "&.active": {
      color: "#FFB476",
      borderBottom: "1px solid #FFB476",
    },
  },
  buttonsForSellType: {
    backgroundColor: "transparent",
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    border: "none",
    "&.active": {
      color: "#FFB476",
      borderBottom: "1px solid #FFB476",
    },
  },
  daysSection: {
    backgroundColor: "tranparent",
    textAlign: "center",
    margin: "6px 0px",
    height: "40px",
    minWidth: "47px",
    width: "100px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    padding: "0",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  icon: {
    fill: "#FFB476",
  },
  buyAndSellSection: {
    border: "1px solid #494949",
    padding: " 1% 4%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
    },
  },
  PinSectionContainer: {
    padding: "28px",
    display: "flex",
    gap: "44px",
    flexDirection: "row",
    flexWrap: "wrap-reverse",
    width: "70vw",
    color: localStorage.getItem("dark") ? "#000" : "#fff",
    backgroundColor: localStorage.getItem("dark") ? "#fff" : "#000",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  PinModalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "20vw",
    height: "40vh",
    width: "70vw",
    margin: "10% 12%",
    inset: 132,
    background: localStorage.getItem("dark") ? "#ffff" : "#1a1a1a",
    border: localStorage.getItem("dark")
      ? "1px solid #0000"
      : "1px solid #1a1a1a",

    // position: "relative",
    right: 20,

    [theme.breakpoints.down("sm")]: {
      right: "0",
      width: "90vw",
      height: "56vh",
    },
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  errorTextField: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-error fieldset": {
        borderColor: "red",
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "red",
    },
  },
  buttonsForOrderSection: {
    backgroundColor: "transparent",
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    border: "none",
    "&.active": {
      color: "#359DB6",
      borderBottom: "1px solid #359DB6",
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
    },
  },
  input: {
    borderRadius: "10px",
    fontSize: 12,

    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    "&:focus": {
      borderRadius: 10,
      border: "1px solid #ff7300",
    },
  },
}))(InputBase);

const CssTextField = withStyles({
  root: {
    fontSize: "0.6rem",
    "& label.Mui-focused": {
      color: "#ff7300",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#ff7300",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "2px solid rgba(0, 123, 255, .25)",
      },
      "&:hover fieldset": {
        border: "2px solid rgba(0, 123, 255, .25)",
      },
      "&.Mui-focused fieldset": {
        borderRadius: "10px",
        border: "1px solid #ff7300",
      },
    },
    "& .Mui-error .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
        borderWidth: "2px",
      },
    },
    otpBox: {
      width: "45px !important",
      height: "45px !important",

      // [theme.breakpoints.down("md")]: {
      // 	width: "30px !important",
      // 	height: "30px !important",
      // },
    },
    BuyButton: {
      backgroundColor: "#359DB6",
      textAlign: "center",
      margin: "6px 0px",
      borderRadius: "10px",
      width: "25%",
      fontSize: "0.8rem",
      fontWeight: "bold",
    },
  },
})(TextField);

const useHelperTextStyles = makeStyles(() => ({
  root: {
    fontSize: "0.6rem",
  },
}));

const BuyStockModal = (props) => {
  const {
    open,
    buyOpen,
    walletModalOpen,
    handleWalletModal,
    stockDetails,
    handleBuyStocks,
    currType,
    currScreen,
    handleClose,
    title,
    buyStocks,
    createStock,
    createStockAccount,
    handleState,
    stockAccount,
    minQuantity,
    currencyOption,
    tradeStockEngine,
    notify,
    getStockPriceHistory,
    stockPriceData,
    getStockOrdeBook,
    orderBook,
    stockList,
    userStocksTransations,
    userStockTransactionCount,
    stockTransactionLoading,
    profile,
    stockPriceLoading,
    storeCurrentStockid,
    currentStockId,
    singleStockPriceObj,
    getPortfolioEngine,
    singleUserStockList,
    getEngineWallet,
    walletData,
    tab,
    getStockTxnEngine,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [quantity, setQuantity] = useState(0);
  const [security, setSecurity] = useState("");
  const [error, setError] = useState("");
  const [transactionType, setTransactionType] = useState("Buy");
  const [currencyType, setCurrencyType] = useState(currType);
  const [type, setType] = useState("Limit Price");
  const [activeButton, setActiveButton] = useState(0);
  const [activeBuyTransaction, setActiveBuyTransaction] = useState("Buy");
  const [activeSellTransaction, setActiveSellTransaction] = useState(null);
  const [price, setPrice] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [showPinSection, setShowPinSection] = useState(false);
  const [buyHelperText, setBuyHelperText] = useState(null);
  const [priceRangeHelperText, setPriceRangeHelperText] = useState("null");
  const [sellHelperText, setSellHelperText] = useState(null);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [orderType, setOrderType] = useState("OB");
  const [totalSellOrder, setTotalSellOrder] = useState(0);
  const [totalBuyOrder, setTotalBuyOrder] = useState(0);

  useEffect(() => {
    if (type == "Limit Price" && transactionType == "Sell") {
      const availableQuantity = singleUserStockList?.[0]?.quantity ?? 0;
      if (quantity > availableQuantity) {
        setSellHelperText(
          "Total Quantity should not be greater than available Stocks"
        );
        sellHelperFunction();
      } else {
        setSellHelperText(null);
      }
    } else {
      if (type == "Market Price" && transactionType == "Buy") {
        const totalSellOrderQuantity =
          orderBook &&
          orderBook.sellOrder.reduce(
            (total, order) => total + order.quantity,
            0
          );
        setTotalSellOrder(totalSellOrderQuantity);
        buyHelperFunction();
        updateMarketPrice(totalSellOrderQuantity);
      } else if (type == "Market Price" && transactionType == "Sell") {
        const totalBuyOrderQuantity =
          orderBook &&
          orderBook.buyOrder.reduce(
            (total, order) => total + order.quantity,
            0
          );
        setTotalBuyOrder(totalBuyOrderQuantity);
        sellHelperFunction();
        updateMarketPrice(totalBuyOrderQuantity);
      }
    }
  }, [quantity, singleUserStockList, type, singleStockPriceObj]);

  useEffect(() => {
    getStockOrdeBook({
      stockId: stockDetails.stockId,
      currencyName: currencyType,
    });
    storeCurrentStockid({
      stockId: stockDetails.stockId,
      price: stockDetails.stockPrice || stockDetails?.currentPrice,
      currencyType: currencyType,
    });
    getPortfolioEngine({
      stockId: stockDetails.stockId,
    });
    getEngineWallet({
      walletName: currencyType,
    });
  }, []);

  const time = ["1D", "1W", "1M", "3M", "6M", "9M", "1y"];
  const setToMidnight = (date) => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  useEffect(() => {
    getStockOrdeBook({
      stockId: stockDetails.stockId,
      currencyName: currencyType,
    });
  }, [currencyType]);

  const calculateStartDate = (duration) => {
    const currentDate = new Date();
    let newStartDate;

    switch (duration) {
      case "1D":
        newStartDate = new Date();
        break;
      case "1W":
        newStartDate = new Date(currentDate.setDate(currentDate.getDate() - 7));
        break;
      case "1M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 1)
        );
        break;
      case "3M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 3)
        );
        break;
      case "6M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 6)
        );
        break;
      case "9M":
        newStartDate = new Date(
          currentDate.setMonth(currentDate.getMonth() - 9)
        );
        break;
      case "1y":
        newStartDate = new Date(
          currentDate.setFullYear(currentDate.getFullYear() - 1)
        );
        break;
      default:
        newStartDate = new Date();
    }

    return setToMidnight(newStartDate);
  };

  const currencyLabels = {
    COG100: "COG 100%",
    CB25CO75: "Cog 75%,CBC 25%",
    CB50CO50: "Cog 50%,CBC 50%",
    CB75CO25: "Cog 25%,CBC 75%",
    CBC100: "CBC 100%",
  };

  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: localStorage.getItem("dark") ? "#fff" : "#212121",
        // marginTop: "50px",
        fontSize: "12px",
        width: "auto",

        // color: "#fff",
      },
    },
  };

  const handleQuantity = (e) => {
    let inputValue = e.target.value.trim();
    let parsedValue = inputValue === "" ? "" : parseInt(inputValue, 10);

    if (parsedValue === "" || isNaN(parsedValue)) {
      setQuantity(inputValue);
      setError("");
    } else {
      if (
        Number(parsedValue) >
        Number(stockDetails?.availableQuantity) /
          Number(stockDetails?.minQuantity)
      ) {
        setError("You cannot purchase more than available lots");
      } else {
        setError("");
      }
      setQuantity(parsedValue);
    }
  };

  // const handlePrice = (e) => {
  //   const newPrice = e.target.value;
  //   setPrice(isNaN(newPrice) ? 0 : newPrice);
  // };

  const handlePrice = (e) => {
    const input = e.target.value;
    const regex = /^\d*\.?\d{0,6}$/;

    if (regex.test(input)) {
      setPrice(input);
    }
  };

  const stockHandler = () => {
    setConfirmLoader(true);
    if (security === "" || security?.length < 6) {
      setError("Please enter 6 digit PIN");
    } else {
      let reqBody = {
        stockId: stockDetails?.stockId,
        walletName: currencyType,
        isBuy: transactionType === "Buy" ? true : false,
        orderType: type === "Limit Price" ? "limit" : "market",
        quantity: quantity,
        price: parseFloat(price),
        pin: Number(security),
      };
      tradeStockEngine(reqBody)
        .then((res) => {
          const { status, successMessage, errorMessage, error } = res;
          if (status) {
            notify(successMessage, "success");
            setConfirmLoader(false);
            closeModal();
            setQuantity(0);
            setPrice("");
            getPortfolioEngine({
              stockId: stockDetails.stockId,
            });
            storeCurrentStockid({
              stockId: stockDetails.stockId,
              price: stockDetails.stockPrice || stockDetails?.currentPrice,
              currencyType: currencyType,
            });
          } else {
            notify(errorMessage || error, "error");
            setConfirmLoader(false);
            closeModal();
            setQuantity(0);
            setPrice("");
            getPortfolioEngine({
              stockId: stockDetails.stockId,
            });
          }
        })
        .catch((err) => {
          notify(err?.errorMessage, "error");
          setConfirmLoader(false);
          closeModal();
          setQuantity(0);
          setPrice(null);
          getPortfolioEngine({
            stockId: stockDetails.stockId,
          });
        });
    }
  };

  const setPin = (i) => {
    if (isNaN(i)) {
      setError("Please enter numeric value");
      return;
    } else {
      setError("");
      setSecurity(i);
    }
  };

  const handleCreateAccount = () => {
    createStockAccount().then(() => {
      handleState();
    });
  };

  const handleDecreaseAmount = () => {
    // let updatedQuantity = quantity - minQuantity;
    if (quantity <= 0) {
      return;
    }
    setQuantity(quantity - 1);
  };

  const handleIncreaseQuantity = () => {
    // let updatedQuantity = minQuantity + quantity;
    setQuantity(quantity + 1);
  };

  const handleTransactionSelection = (type) => {
    setType("Limit Price");
    setTransactionType(type);
    if (type == "Buy") {
      setActiveSellTransaction(null);
      setActiveBuyTransaction("Buy");
      setQuantity(0);
      setPrice("");
      setBuyHelperText(null);
    } else {
      setActiveSellTransaction("Sell");
      setActiveBuyTransaction(null);
      setQuantity(0);
      setPrice("");
      setSellHelperText(null);
    }
  };

  const handleCurrencyChange = (event) => {
    setQuantity(0);
    setPrice("");
    setType("Limit Price");
    setCurrencyType(event.target.value);
    getStockPriceHistory({
      stockId: stockDetails.stockId,
      currencyName: event.target.value,
      from: startDate,
      till: "",
    });
    storeCurrentStockid({
      stockId: stockDetails.stockId,
      price: stockDetails.stockPrice || stockDetails?.currentPrice,
      currencyType: event.target.value,
    });
    getEngineWallet({
      walletName: event.target.value,
    });
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    if (title == undefined) {
      const formattedDate = formatDateToYYYYMMDD(startDate);
      setStartDate(formattedDate);
      getStockPriceHistory({
        stockId: stockDetails.stockId,
        currencyName: currencyType,
        from: formattedDate,
        till: "",
      });
    }
  }, []);

  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleButtonClick = (index) => {
    setActiveButton(index);
    const selectedDuration = time[index];
    const calculatedDate = calculateStartDate(selectedDuration);
    const formattedDate = formatDateToYYYYMMDD(calculatedDate);
    setStartDate(formattedDate);
    getStockPriceHistory({
      stockId: stockDetails.stockId,
      currencyName: currencyType,
      from: formattedDate,
      till: "",
    });
  };

  const getCurrentPrice = () => {
    if (currScreen == "portfolio") {
      const priceObj = stockDetails?.currentPrice.find(
        (price) => price.currencyName === currencyType
      );
      let obj = priceObj ? priceObj.currentPrice : "N/A";
      return obj.toString();
    }
  };

  const totalAmount = price * quantity;

  useEffect(() => {
    if (transactionType == "Buy") {
      const availableAmount = walletData?.[0]?.amount ?? 0;
      if (totalAmount > availableAmount) {
        setBuyHelperText("Total Amount should not be greater than Balance");
      } else {
        setBuyHelperText(null);
      }
    }
  }, [totalAmount, walletData]);

  const closeModal = () => {
    if (title == "0" || title == "1") {
      setShowPinSection(false);
      setSecurity("");
      handleBuyStocks({
        stockDetails: stockDetails,
        currencyType: currencyType,
        title: undefined,
      });
    } else {
      handleClose();
      setSecurity("");
      setQuantity(1);
      setError("");
      if (tab == "portfolio") {
        getPortfolioEngine({
          limit: 10,
          page: 0,
        });
      }
    }
  };

  const handleOrderType = (type) => {
    setOrderType(type);
    // if (type == "MO") {
    //   getStockTxnEngine({
    //     stockId: stockDetails?.stockId,
    //     limit: 10,
    //     page: 0,
    //     orderStatus: "pending",
    //   });
    // }
  };

  const validateLimitPriceRange = () => {
    const lowestvalue = (singleStockPriceObj?.currentPrice || 0) * 0.95;
    const highestValue = (singleStockPriceObj?.currentPrice || 0) * 1.05;
    const numberPrice = Number(price);
    if (
      price &&
      typeof numberPrice == "number" &&
      lowestvalue > numberPrice &&
      type !== "Market Price" &&
      transactionType == "Sell"
    ) {
      setPriceRangeHelperText(
        `Limit price should be greater than € ${priceFormatter(lowestvalue)}`
      );
    } else if (priceRangeHelperText) {
      setPriceRangeHelperText(null);
    }
  };

  useEffect(() => {
    validateLimitPriceRange();
  }, [price, type, currencyType, singleStockPriceObj, currType]);

  const calculateBuyAveragePrice = (selectedQuantity) => {
    let remainingQuantity = selectedQuantity;
    let totalCost = 0;
    let totalQuantity = 0;
    let buyOrder = orderBook?.buyOrder;

    for (let i = 0; i < buyOrder?.length && remainingQuantity > 0; i++) {
      const order = buyOrder[i];
      const quantityToUse = Math.min(order.quantity, remainingQuantity);

      totalCost += order.price * quantityToUse;
      totalQuantity += quantityToUse;
      remainingQuantity -= quantityToUse;
    }

    return totalQuantity > 0 ? totalCost / totalQuantity : 0;
  };

  const calculateSellAveragePrice = (selectedQuantity) => {
    let remainingQuantity = selectedQuantity;
    let totalCost = 0;
    let totalQuantity = 0;
    let sellOrder = orderBook?.sellOrder;

    for (let i = 0; i < sellOrder?.length && remainingQuantity > 0; i++) {
      const order = sellOrder[i];
      const quantityToUse = Math.min(order.quantity, remainingQuantity);

      totalCost += order.price * quantityToUse;
      totalQuantity += quantityToUse;
      remainingQuantity -= quantityToUse;
    }

    return totalQuantity > 0 ? totalCost / totalQuantity : 0;
  };

  const updateMarketPrice = (totalQty) => {
    if (type == "Market Price") {
      if (transactionType == "Buy" && quantity > totalQty) {
        setPrice("");
      } else if (transactionType == "Sell" && quantity > totalQty) {
        setPrice("");
      } else {
        const finalValue =
          transactionType == "Buy"
            ? calculateSellAveragePrice(quantity)
            : calculateBuyAveragePrice(quantity);
        setPrice(priceFormatter(finalValue));
      }
    }
  };

  const buyHelperFunction = () => {
    if (type === "Market Price" && transactionType === "Buy") {
      if (totalSellOrder === 0) {
        return "No sell orders";
      } else if (quantity > totalSellOrder) {
        return "Not enough sell order available";
      } else {
        return null;
      }
    }
  };

  const sellHelperFunction = () => {
    if (type === "Limit Price" && transactionType === "Sell") {
      return sellHelperText;
    } else if (type === "Market Price" && transactionType === "Sell") {
      if (totalBuyOrder === 0) {
        return "No buy orders";
      } else if (quantity > totalBuyOrder) {
        return "Not enough buy order available";
      } else {
        return null;
      }
    }
  };
  return (
    <Box
      style={{
        width: "100%",
        background: localStorage.getItem("dark") ? "#fff" : "#000",
        alignItems: "center",
        marginTop: "1.5rem",
      }}
    >
      <Grid
        component={Paper}
        container
        xs={12}
        className={
          createStock ? classes.createAccountContainer : classes.modalContainer
        }
      >
        <Grid item container xs={12} className={classes.modalHeading}>
          <Typography style={{ paddingLeft: "20px", fontSize: "1.2rem" }}>
            {createStock
              ? "Create Stock Account"
              : title == "0"
              ? "Buy Stock Preview"
              : title == "1"
              ? "Sell Stock Preview"
              : "Stock Details"}
          </Typography>
          <IconButton id="close-buy-stocks" onClick={closeModal}>
            <CancelOutlined />
          </IconButton>
        </Grid>
        {(title == "0" || title == "1") && showPinSection ? (
          <>
            <Modal
              // title={title}
              open={title == "0" || title == "1"}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className={classes.PinModalContainer}
            >
              <div>
                <Grid item container xs={12} className={classes.modalHeading}>
                  <Typography
                    style={{ paddingLeft: "20px", fontSize: "1.2rem" }}
                  >
                    {title == "0" ? "Buy Stock Preview" : "Sell Stock Preview"}
                  </Typography>
                  <IconButton id="close-buy-stocks" onClick={closeModal}>
                    <CancelOutlined />
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  className={classes.PinSectionContainer}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    direction="column"
                    style={{
                      display: "flex",
                      borderRight: "1px solid #4F4F4F",
                      gap: "20px",
                    }}
                  >
                    <Typography>PIN</Typography>
                    <Typography>Confirm Your Transaction</Typography>
                    <FormControl>
                      <OTPInput
                        numInputs={6}
                        renderSeparator={<span style={{ margin: "2%" }}></span>}
                        renderInput={(props) => (
                          <input
                            {...props}
                            className={classes.otpBox}
                            autoComplete="new-password"
                          />
                        )}
                        shouldAutoFocus
                        isInputNum={true}
                        inputType="password"
                        value={security}
                        onChange={setSecurity}
                        inputStyle={{
                          border: "1px solid transparent",
                          borderRadius: "8px",
                          color: localStorage.getItem("dark")
                            ? "black"
                            : "#DCDCDC",
                          fontWeight: "400",
                          backgroundColor: localStorage.getItem("dark")
                            ? "#c6c6c6"
                            : "#2e2e2e",
                        }}
                      />
                    </FormControl>
                    <Button
                      onClick={() => {
                        stockHandler();
                      }}
                      style={{ width: "30%", height: "2.3rem" }}
                      className={classes.PayButton}
                      disabled={security?.length == 6 ? false : true}
                    >
                      {confirmLoader ? <SmallSpinner /> : "Confirm"}
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    direction="column"
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Stock Quantity</Typography>
                      <Typography>{quantity}</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Currency Option</Typography>
                      <Typography>{currencyLabels[currencyType]}</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Typography>Transaction Total</Typography>
                      <Typography>{totalAmount}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Modal>
          </>
        ) : (
          <>
            {createStock ? (
              <>
                <Grid item container className={classes.CreateDetailBox}>
                  <Grid item xs={12}>
                    <Typography>
                      You don't have a Stock account. Do you wish to create
                      Stock account for buying the stocks?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.createButtonGrid}>
                    <Button
                      id={"create-account-yes"}
                      className={classes.CreateButton}
                      onClick={handleCreateAccount}
                    >
                      Yes
                    </Button>

                    <Button
                      id={"create-account-no"}
                      className={classes.CreateButton}
                      onClick={closeModal}
                    >
                      No
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : stockAccount ? (
              <>
                <Spinner />
              </>
            ) : (
              <>
                <Grid
                  item
                  container
                  style={{
                    padding: "28px",
                    display: "flex",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Typography>
                      <span style={{ color: "#359DB6" }}>{currencyType}</span>{" "}
                      <>
                        &nbsp;&nbsp;€{" "}
                        {`${formatPrice(singleStockPriceObj?.currentPrice)}`}
                      </>
                    </Typography>

                    <Typography
                      style={{ fontWeight: "bold", marginBottom: "0.5rem" }}
                    >
                      {stockDetails?.stockName}
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      sm={10}
                      style={{
                        border: "1px solid #494949",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: "10px",
                      }}
                    >
                      <Grid item style={{ height: "45vh", width: "100%" }}>
                        <LineChart
                          data={stockPriceData}
                          loadings={stockPriceLoading}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      {time.map((time, index) => {
                        return (
                          <Button
                            key={index}
                            className={`${classes.timeDurationButton} ${
                              activeButton === index ? "active" : ""
                            }`}
                            onClick={() => handleButtonClick(index)}
                          >
                            {time}
                          </Button>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    style={{}}
                    className={classes.buyAndSellSection}
                  >
                    <Grid
                      item
                      style={{
                        borderBottom: "1px solid #494949",
                        display: "flex",
                        gap: "20px",
                      }}
                    >
                      <Button
                        className={`${classes.buttonsForBuyType} ${
                          activeBuyTransaction === "Buy" ? "active" : ""
                        }`}
                        onClick={() => handleTransactionSelection("Buy")}
                      >
                        Buy
                      </Button>
                      <Button
                        className={`${classes.buttonsForSellType} ${
                          activeSellTransaction === "Sell" ? "active" : ""
                        }`}
                        onClick={() => handleTransactionSelection("Sell")}
                      >
                        Sell
                      </Button>
                    </Grid>
                    <Grid item style={{ padding: "10px" }}>
                      <>
                        {transactionType == "Sell" ? (
                          <div style={{ marginTop: "1rem" }}>
                            <Typography style={{ color: "#359DB6" }}>
                              Available Stock :{" "}
                              {singleUserStockList &&
                              singleUserStockList?.length > 0
                                ? singleUserStockList[0].quantity
                                : 0}
                            </Typography>
                          </div>
                        ) : null}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "20%",
                          }}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            {stockDetails?.name}
                          </Typography>
                          <Typography style={{ marginTop: "1rem" }}>
                            Stock Quantity
                          </Typography>
                          <TextField
                            size="small"
                            variant="outlined"
                            fullWidth
                            onChange={handleQuantity}
                            value={
                              quantity === "" || isNaN(quantity) ? 0 : quantity
                            }
                            helperText={
                              transactionType == "Buy"
                                ? buyHelperFunction()
                                : sellHelperFunction()
                            }
                            error={
                              !!(transactionType === "Buy"
                                ? buyHelperFunction()
                                : sellHelperFunction())
                            }
                            className={
                              transactionType === "Buy"
                                ? buyHelperFunction()
                                  ? classes.errorTextField
                                  : classes.quantityField
                                : sellHelperFunction()
                                ? classes.errorTextField
                                : classes.quantityField
                            }
                          />
                        </div>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        >
                          <Typography style={{ marginTop: "0.5rem" }}>
                            Wallet Currency Option
                          </Typography>
                          <TextField
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={currencyLabels[currencyType] || ""}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Select
                                    value=""
                                    onChange={handleCurrencyChange}
                                    input={<BootstrapInput />}
                                    IconComponent={ArrowDropDownIcon}
                                    MenuProps={MenuProps}
                                    id="paymentTypeDropdown"
                                    classes={{ icon: classes.icon }}
                                  >
                                    <MenuItem
                                      value="COG100"
                                      id="cog100"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor:
                                          currencyType == "COG100"
                                            ? "#1B5563"
                                            : theme.palette.background.default,
                                        color:
                                          !localStorage.getItem("dark") ||
                                          currencyType === "COG100"
                                            ? "#fff"
                                            : "#212121",
                                      }}
                                    >
                                      COG 100%
                                    </MenuItem>
                                    <MenuItem
                                      value="CB25CO75"
                                      id="cog75"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor:
                                          currencyType == "CB25CO75"
                                            ? "#1B5563"
                                            : theme.palette.background.default,
                                        color:
                                          !localStorage.getItem("dark") ||
                                          currencyType === "CB25CO75"
                                            ? "#fff"
                                            : "#212121",
                                      }}
                                    >
                                      Cog 75%,CBC 25%
                                    </MenuItem>
                                    <MenuItem
                                      value="CB50CO50"
                                      id="CBC50"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor:
                                          currencyType == "CB50CO50"
                                            ? "#1B5563"
                                            : theme.palette.background.default,
                                        color:
                                          !localStorage.getItem("dark") ||
                                          currencyType === "CB50CO50"
                                            ? "#fff"
                                            : "#212121",
                                      }}
                                    >
                                      Cog 50%,CBC 50%
                                    </MenuItem>
                                    <MenuItem
                                      value="CB75CO25"
                                      id="cog25"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor:
                                          currencyType == "CB75CO25"
                                            ? "#1B5563"
                                            : theme.palette.background.default,
                                        color:
                                          !localStorage.getItem("dark") ||
                                          currencyType === "CB75CO25"
                                            ? "#fff"
                                            : "#212121",
                                      }}
                                    >
                                      Cog 25%,CBC 75%
                                    </MenuItem>
                                    <MenuItem
                                      value="CBC100"
                                      id="cbc100"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor:
                                          currencyType == "CBC100"
                                            ? "#1B5563"
                                            : theme.palette.background.default,
                                        color:
                                          !localStorage.getItem("dark") ||
                                          currencyType === "CBC100"
                                            ? "#fff"
                                            : "#212121",
                                      }}
                                    >
                                      CBC 100%
                                    </MenuItem>
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        >
                          <Typography style={{ marginTop: "0.5rem" }}>
                            {transactionType == "Buy"
                              ? ` Stock Buy Value (${type})`
                              : `Stock Sell Value (${type})`}
                          </Typography>
                          <TextField
                            type="number"
                            disabled={type === "Market Price"}
                            variant="outlined"
                            fullWidth
                            margin="dense"
                            value={price}
                            onChange={handlePrice}
                            helperText={
                              (transactionType == "Buy"
                                ? buyHelperText
                                : null) || priceRangeHelperText
                            }
                            error={
                              !!(transactionType == "Buy"
                                ? buyHelperText
                                : null) || !!priceRangeHelperText
                            }
                            className={
                              transactionType == "Buy"
                                ? buyHelperText
                                  ? classes.errorTextField
                                  : null
                                : null
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Select
                                    value=""
                                    variant="outlined"
                                    onChange={handleTypeChange}
                                    input={<BootstrapInput />}
                                    MenuProps={MenuProps}
                                    IconComponent={ArrowDropDownIcon}
                                    style={{ fontSize: "12px" }}
                                    classes={{ icon: classes.icon }}
                                  >
                                    <MenuItem
                                      value="Limit Price"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor:
                                          type == "Limit Price"
                                            ? "#1B5563"
                                            : theme.palette.background.default,
                                        color:
                                          !localStorage.getItem("dark") ||
                                          type === "Limit Price"
                                            ? "#fff"
                                            : "#212121",
                                      }}
                                    >
                                      Limit Price
                                    </MenuItem>

                                    <MenuItem
                                      disabled={quantity == 0 ? true : false}
                                      value="Market Price"
                                      style={{
                                        fontSize: "12px",
                                        backgroundColor:
                                          type == "Market Price"
                                            ? "#1B5563"
                                            : theme.palette.background.default,
                                        color:
                                          !localStorage.getItem("dark") ||
                                          type === "Market Price"
                                            ? "#fff"
                                            : "#212121",
                                      }}
                                    >
                                      Market Price
                                    </MenuItem>
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>

                        <Box
                          display="flex"
                          flexDirection="column "
                          justifyContent="space-between"
                          mt={2}
                          color="#C49C39"
                        >
                          {transactionType == "Buy" ? (
                            <Box display="flex" alignItems="center">
                              <Typography>Balance</Typography>
                              <Typography>
                                &nbsp;&nbsp;&nbsp; €{" "}
                                {walletData && walletData?.length > 0
                                  ? parseFloat(walletData[0].amount).toFixed(2)
                                  : 0}
                              </Typography>
                            </Box>
                          ) : null}

                          <Box display="flex" alignItems="center">
                            <Typography>Total Amount</Typography>

                            <Typography style={{ color: "#C49C39" }}>
                              &nbsp;&nbsp;&nbsp; € {priceFormatter(totalAmount)}
                            </Typography>
                          </Box>
                        </Box>

                        <Grid
                          item
                          container
                          xs={12}
                          direction="row"
                          justifyContent="center"
                        >
                          {transactionType == "Buy" ? (
                            <Button
                              className={classes.PayButton}
                              onClick={() => {
                                handleBuyStocks({
                                  stockDetails: stockDetails,
                                  currencyType: currencyType,
                                  title: "0",
                                });
                                setShowPinSection(true);
                              }}
                              id={quantity * stockDetails?.stockPrice}
                              disabled={
                                !quantity ||
                                error !== "" ||
                                isNaN(quantity) ||
                                typeOf(quantity) === String ||
                                quantity < 1 ||
                                totalAmount <= 0 ||
                                totalAmount > (walletData?.[0]?.amount ?? 0) ||
                                priceRangeHelperText
                              }
                            >
                              Buy
                            </Button>
                          ) : (
                            <Button
                              className={classes.PayButton}
                              onClick={() => {
                                handleBuyStocks({
                                  stockDetails: stockDetails,
                                  currencyType: currencyType,
                                  title: "1",
                                });
                                setShowPinSection(true);
                              }}
                              id={quantity * stockDetails?.stockPrice}
                              disabled={
                                !quantity ||
                                error !== "" ||
                                isNaN(quantity) ||
                                typeOf(quantity) === String ||
                                quantity < 1 ||
                                totalAmount <= 0 ||
                                quantity >
                                  (singleUserStockList?.[0]?.quantity ?? 0) ||
                                priceRangeHelperText
                              }
                            >
                              Sell
                            </Button>
                          )}
                        </Grid>
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
      <Typography
        style={{ paddingLeft: "18px", fontSize: "1.5rem", color: "#C1C1C1" }}
      ></Typography>
      <Grid
        item
        style={{
          borderBottom: "1px solid #494949",
          display: "flex",
          gap: "20px",
          paddingLeft: "20px",
        }}
      >
        <Button
          className={`${classes.buttonsForOrderSection} ${
            orderType === "OB" ? "active" : ""
          }`}
          onClick={() => handleOrderType("OB")}
        >
          Order Book
        </Button>
        <Button
          className={`${classes.buttonsForOrderSection} ${
            orderType === "MO" ? "active" : ""
          }`}
          onClick={() => setOrderType("MO")}
        >
          My Orders
        </Button>
      </Grid>
      {orderType == "OB" ? (
        <StockOrderBook
          stockDetails={stockDetails}
          orderBook={orderBook}
          currencyType={currencyType}
        />
      ) : (
        <StockTransactionList
          stockId={stockDetails.stockId}
          location="BuyStock"
        />
      )}
    </Box>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    stockAccount: userReducer.loadings.stockAccount,
    stockPriceData: userReducer.stockPriceData,
    stockPriceLoading: userReducer.loadings.stockPrice,
    orderBook: userReducer.orderBook,
    userStocksTransations: userReducer.userStocksTransations,
    userStockTransactionCount: userReducer.userStockTransactionCount,
    stockList: userReducer.stockList,
    stockTransactionLoading: userReducer.loadings.stockTransaction,
    profile: userReducer.profile,
    currentStockId: userReducer.currentStockId,
    singleStockPriceObj: userReducer.singleStockPriceArr,
    singleUserStockList: userReducer.singleUserStock,
    walletData: userReducer.walletData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    buyStocks: (data) => dispatch(actionCreator.buyStocks(data)),
    createStockAccount: () => dispatch(actionCreator.createStockAccount()),
    tradeStockEngine: (data) => dispatch(actionCreator.tradeStockEngine(data)),
    getStockPriceHistory: (data) =>
      dispatch(actionCreator.getStockPriceHistory(data)),
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    getStockOrdeBook: (data) => dispatch(actionCreator.getStockOrdeBook(data)),
    storeCurrentStockid: (data) =>
      dispatch(actionCreator.storeCurrentStockid(data)),
    getPortfolioEngine: (data) =>
      dispatch(actionCreator.getPortfolioEngine(data)),
    getEngineWallet: (data) => dispatch(actionCreator.getEngineWallet(data)),
    getStockTxnEngine: (data) =>
      dispatch(actionCreator.getStockTxnEngine(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BuyStockModal);
