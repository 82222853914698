import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import Logo from "../../../asset/images/exchangeDirectory/medicalRelief/Logo.svg";
import Im1 from "../../../asset/images/exchangeDirectory/medicalRelief/Im1.svg";
import WhatWeDo1 from "../../../asset/images/exchangeDirectory/medicalRelief/WhatWeDo1.svg";
import WhatWeDo2 from "../../../asset/images/exchangeDirectory/medicalRelief/WhatWeDo2.svg";
import WhatWeDo3 from "../../../asset/images/exchangeDirectory/medicalRelief/WhatWeDo3.svg";
import Involved1 from "../../../asset/images/exchangeDirectory/medicalRelief/Involved1.svg";
import Involved2 from "../../../asset/images/exchangeDirectory/medicalRelief/Involved2.svg";
import Involved3 from "../../../asset/images/exchangeDirectory/medicalRelief/Involved3.svg";
import Involved4 from "../../../asset/images/exchangeDirectory/medicalRelief/Involved4.svg";
import InvolvedXs1 from "../../../asset/images/exchangeDirectory/medicalRelief/InvolvedXs1.svg";
import InvolvedXs2 from "../../../asset/images/exchangeDirectory/medicalRelief/InvolvedXs2.svg";
import InvolvedXs3 from "../../../asset/images/exchangeDirectory/medicalRelief/InvolvedXs3.svg";
import InvolvedXs4 from "../../../asset/images/exchangeDirectory/medicalRelief/InvolvedXs4.svg";

import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  const dark = theme.palette.type == "dark";

  return {
    container: {},
    rowContainer: {
      padding: "1.8rem 0",
      [theme.breakpoints.down("sm")]: {
        padding: "0.8rem 0",
      },
    },
    bottomBorder: {
      borderBottom: `1px solid ${dark ? "#fff" : "#ADA5D399"}`,
    },
    logoImage: {
      width: "2rem",
      [theme.breakpoints.down("sm")]: {
        width: "1.6rem",
      },
      [theme.breakpoints.down("xs")]: {
        width: "1.2rem",
      },
    },
    descriptionText: {
      fontSize: "0.9rem",
      fontWeight: 500,
      color: dark ? "#fff" : "#1A1A1A",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.7rem",
      },
    },
    logoName: {
      fontSize: "1rem",
      fontWeight: 600,
      color: "#5E548E",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    logoNameSecondaryColor: {
      color: "#BE95C4",
    },
    contactButton: {
      textTransform: "none",
      border: "1.5px solid #5E548E",
      color: "#5E548E",
      fontSize: "0.8rem",
      fontWeight: 550,
      padding: "0.3rem 1.3rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.6rem",
      },
    },
    Im1: {
      width: "94%",
      maxWidth: 310,
    },
    headingText: {
      color: "#5E548E",
      fontSize: "1.4rem",
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
    detailText: {
      color: dark ? "#E5E5E5" : "#1A1A1A",
      fontSize: "0.8rem",
      fontWeight: 500,
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.7rem",
      },
    },
    df: {
      display: "flex",
      alignItems: "center",
    },
    dfjsb: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    dfjsa: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    whatToDoCardContainer: {
      padding: "1rem",
      height: "100%",
      width: "96%",
      maxWidth: 200,
      backgroundColor: dark ? "#28233E" : "#7D75A4",
      borderRadius: 22,
    },
    whatToDoImage: {
      width: "100%",
    },
    whoIsInvolvedContainer: {
      backgroundColor: dark ? "#29253A" : "#fff",
      width: "100%",
      maxWidth: 1000,
      borderRadius: 50,
      [theme.breakpoints.down("xs")]: {
        padding: "0 0.6rem",
        borderRadius: 12,
      },
    },
    involvedImage: {
      width: "6rem",
      [theme.breakpoints.down("xs")]: {
        width: "4rem",
      },
    },
    involvedDescriptionContainerOdd: {
      padding: "0.6rem 0.6rem 0.6rem 1.2rem",
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        padding: "0.6rem 0.6rem 0.6rem 0rem",
      },
    },
    involvedDescriptionContainerEven: {
      padding: "0.6rem 1.2rem 0.6rem 0.6rem",
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        padding: "0.6rem 0rem 0.6rem 0.6rem",
      },
    },
    oddName: {
      color: "#5E548E",
      fontWeight: 800,
      textAlign: "right",
    },
    oddDescription: {
      color: dark ? "#DCDCDC" : "##1A1A1A",
      textAlign: "right",
    },
    evenName: {
      color: "#5E548E",
      fontWeight: 800,
      textAlign: "left",
    },
    evenDescription: {
      color: dark ? "#DCDCDC" : "#1A1A1A",
      textAlign: "left",
    },
    contactStyle: {
      fontSize: "1rem",
      fontWeight: 500,
      color: "#6F6F6F",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.9rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.8rem",
      },
    },
    whiteColor: {
      color: "#fff",
    },
    whatWeDoCardData: {
      maxWidth: 850,
    },
  };
});

const MedicalRelief = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const dark = theme.palette.type == "dark";

  const matchSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchXs = useMediaQuery(theme.breakpoints.down("xs"));

  const whatWeDoCardData = [
    { img: WhatWeDo1, text: "Reliable and timely service for our providers" },
    { img: WhatWeDo2, text: "Competitive purchasing strategies" },
    {
      img: WhatWeDo3,
      text: "Seamless integrations with like-minded businesses",
    },
  ];

  const whoIsInvolvedData = [
    {
      img: matchXs ? InvolvedXs1 : Involved1,
      name: "William (Bill) Dalzell",
      description:
        "Experienced in international business, Bill held senior roles in forestry, steel, and finance. He's rebuilding and expanding the FTM Fund after the passing of his friend and business partner Mr. Endre.",
    },
    {
      img: matchXs ? InvolvedXs2 : Involved2,
      name: "Brenda Dalzell",
      description:
        "Brenda, experienced in the health industry, brings international expertise from various sectors. Her management skills and understanding of medical receivables are vital in revitalizing FTM alongside her husband Bill.",
    },
    {
      img: matchXs ? InvolvedXs3 : Involved3,
      name: "Martin Fairbarn",
      description:
        "A retired lawyer and English national, Martin has extensive experience in Hong Kong. He offers private consultancy services and serves as legal counsel for FTM Ltd since 2010.",
    },
    {
      img: matchXs ? InvolvedXs4 : Involved4,
      name: "Charles Sizemore",
      description:
        "Charles is the Chief Investment Officer at Sizemore Capital Management. A registered investment advisor, he specializes in dividend-focused portfolios and tax-efficient alternative allocations.",
    },
  ];

  const heading = (text) => (
    <Box className={classes.dfjsa}>
      <Typography className={classes.headingText}>{text}</Typography>
    </Box>
  );

  const detail = (text, className = "") => (
    <Typography className={clsx(classes.detailText, className)}>
      {text}
    </Typography>
  );

  const benefits = (
    <Grid item xs={12}>
      <Typography className={clsx(classes.logoName)}>Benefits:</Typography>
      <ul>
        <li>
          <Typography className={clsx(classes.descriptionText)}>
            Recession Proof
          </Typography>
        </li>
        <li>
          <Typography className={clsx(classes.descriptionText)}>
            Unaffected by falls in the market
          </Typography>
        </li>
        <li>
          <Typography className={clsx(classes.descriptionText)}>
            Non-correlated to equities
          </Typography>
        </li>
        <li>
          <Typography className={clsx(classes.descriptionText)}>
            Investment strategy dating back to 1997
          </Typography>
        </li>
      </ul>
    </Grid>
  );

  const whatWeDoCard = (data) => {
    return (
      <Box className={clsx(classes.whatToDoCardContainer)}>
        <img src={data.img} className={clsx(classes.whatToDoImage)} />
        {detail(data.text, dark ? "" : classes.whiteColor)}
      </Box>
    );
  };

  const whoIsInvolvedCard = (data, index) => {
    let image = <img src={data.img} className={clsx(classes.involvedImage)} />;
    return (
      <Box className={clsx(classes.whoIsInvolvedContainer, classes.dfjsb)}>
        {index % 2 && !matchXs ? (
          <>
            <Box className={clsx(classes.involvedDescriptionContainerOdd)}>
              <Box style={{ marginBottom: "0.3rem" }}>
                {detail(data.name, classes.oddName)}
              </Box>
              <Box>{detail(data.description, classes.oddDescription)}</Box>
            </Box>
            <Box className={clsx(classes.df)}>{image}</Box>
          </>
        ) : (
          <>
            <Box className={clsx(classes.df)}>{image}</Box>
            <Box className={clsx(classes.involvedDescriptionContainerEven)}>
              <Box style={{ marginBottom: "0.3rem" }}>
                {detail(data.name, classes.evenName)}
              </Box>
              <Box>{detail(data.description, classes.evenDescription)}</Box>
            </Box>
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box className={clsx(classes.container)}>
        <Box className={clsx(classes.rowContainer, classes.bottomBorder)}>
          <Grid container spacing={matchSm ? 2 : 4}>
            <Grid item xs={12}>
              <Box className={clsx(classes.dfjsb)}>
                <Box className={clsx(classes.df)}>
                  <img
                    src={Logo}
                    className={classes.logoImage}
                    style={{ marginRight: "0.3rem" }}
                  />
                  <Typography className={classes.logoName}>
                    MEDICAL
                    <span className={classes.logoNameSecondaryColor}>
                      RELIEF
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <a href="#contact">
                    <Button className={classes.contactButton}>
                      Contact Us
                    </Button>
                  </a>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={matchSm ? 1 : 2}>
                <Grid item xs={7}>
                  <Grid container spacing={matchSm ? 2 : 4}>
                    <Grid item xs={12}>
                      <Typography className={clsx(classes.descriptionText)}>
                        It’s time to take control of your{" "}
                        <span className={clsx(classes.logoName)}>
                          financial future
                        </span>
                        !
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={clsx(classes.descriptionText)}>
                        <span className={clsx(classes.logoName)}>
                          Medical Relief
                        </span>
                        - we help you invest with confidence! Capitalize on our
                        knowledge and insight into an alternative asset class,
                        as we take your investments forward by investing in our
                        innovative venture FTM II.
                      </Typography>
                    </Grid>
                    {matchXs ? null : benefits}
                  </Grid>
                </Grid>
                <Grid item xs={5} className={clsx(classes.dfjsa)}>
                  <img src={Im1} className={clsx(classes.Im1)} />
                </Grid>
                {matchXs ? benefits : null}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={clsx(classes.rowContainer, classes.bottomBorder)}>
          <Grid container spacing={matchSm ? 2 : 4}>
            <Grid item xs={12}>
              {heading("ABOUT")}
            </Grid>
            <Grid item xs={12}>
              {detail(
                "Our story began in March of 2010, with the launch of ‘Forget The Market’ or the ‘FTM’, brainchild of late Mr. Endre Dobozy. We launched as a family of hedge funds, where 90%-95% of the the principal was secured by qualified US medical receivables. The core idea behind its inception was to create an investment strategy that could match or exceed the market long term averages. One in which friends, family and clients could invest without sleepless nights and avoid the nightmares from market drops."
              )}
            </Grid>
            <Grid item xs={12}>
              {detail(
                "Based on the same business model, we created FTM II. An ambitious and innovative company that is reviving Forget The Market’s stellar investment track record. FTM II targets to grow at more than 30% per year by investing exclusively in its lucrative specialist business of funding US medical receivables and it ensures long term success for its faithful investors."
              )}
            </Grid>
          </Grid>
        </Box>
        <Box className={clsx(classes.rowContainer, classes.bottomBorder)}>
          <Grid container spacing={matchSm ? 2 : 4}>
            <Grid item xs={12}>
              {heading("What We Do?")}
            </Grid>
            <Grid item xs={12}>
              {detail(
                "As a company that has turned the alternative investment industry on its head, we plan to grow expansively over the coming years. This growth will be accomplished by expanding the sourcing of receivables and capitalizing on wider opportunity spread in its lucrative specialist business of funding US medical receivables."
              )}
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.dfjsa}>
                <Grid
                  container
                  spacing={matchSm ? 1 : 2}
                  className={clsx(classes.whatWeDoCardData, classes.dfjsa)}
                >
                  {whatWeDoCardData?.map((data, index) => {
                    return (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        key={index}
                        className={clsx(classes.dfjsa)}
                      >
                        {whatWeDoCard(data)}
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className={clsx(classes.rowContainer, classes.bottomBorder)}>
          <Grid container spacing={matchSm ? 2 : 4}>
            <Grid item xs={12}>
              {heading("Who is involved?")}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={matchSm ? 2 : 4}>
                {whoIsInvolvedData?.map((data, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    className={clsx(classes.dfjsa)}
                  >
                    {whoIsInvolvedCard(data, index)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className={clsx(classes.rowContainer)}>
          <Grid container spacing={matchSm ? 2 : 3}>
            <Grid item xs={12}>
              {heading("Contact Us")}
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {detail("You can reach out to us at", classes.contactStyle)}
                </Grid>
                <Grid item xs={12} id="contact">
                  {detail(
                    <>
                      <a
                        href="mailto: support@medicalrelief.online"
                        style={{ color: "#BE95C4", textDecoration: "none" }}
                      >
                        support@medicalrelief.online
                      </a>
                      &nbsp;for any enquiries!
                    </>,
                    classes.contactStyle
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box style={{ backgroundColor: "#BE95C4", height: "2rem" }}></Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default MedicalRelief;
