import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  Modal,
  MenuItem,
  Select,
  Paper,
  Collapse,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  IconButton,
  Backdrop,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
  FormControl,
  Card,
  CardContent,
  TableContainer,
} from "@material-ui/core";
import { useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";

import {
  formatPrice,
  priceFormatter,
} from "../../utils/numberFormater/priceFormater";
import { connect } from "react-redux";
import * as actionCreator from "../../store/action/index";
import { CloseOutlined } from "@material-ui/icons";

import BuyStockModal from "../stocks/newStocks/BuyStockModal";
import StockTransactionList from "./newStocks/StockTransactionList";
const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
    textTransform: "none",
    minWidth: "auto",
    color: "#359DB6",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  icon: {
    fill: "#359DB6",
  },
  icon1: {
    fill: "#359DB6",
    fontSize: "2rem",
  },
  cardBox: {
    outline: "none",
    padding: "10px 30px",
    margin: "1% 0%",
    // width: "100%",
    boxShadow: localStorage?.getItem("dark")
      ? "-4px -4px 10px rgba(58, 58, 58, 0.7)"
      : "none",
    border: localStorage?.getItem("dark")
      ? "1px solid #359DB6 "
      : "1px solid #6993A4",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
  portfolioCardBox: {
    padding: "10px 30px",
    margin: "8px 2px",
    width: "90%",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    // border: localStorage?.getItem("dark")
    //   ? "1px solid #359DB6 "
    //   : "1px solid #C9E5EC",
    // borderRadius: 0,
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: { width: "100%" },
  },
  BuyButton: {
    backgroundColor: "#359DB6",
    textAlign: "center",
    margin: "6px 0px",
    borderRadius: "25px",
    width: "55%",
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "auto",
    },
  },
  logoGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "start",
    },
  },
  textGrid: {
    paddingLeft: "20px",
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
    },
  },
  subText: {
    display: "flex",
    margin: "0",
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
    },
  },
  price: {
    color: localStorage?.getItem("dark") ? "#359DB6" : "#C9E5EC",
  },
  title: {
    color: localStorage.getItem("dark") ? "#1a1a1a" : "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
  listedText: {
    color: "#359DB6",
    fontSize: "0.8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
    },
  },
  viewButton: {
    fontSize: "0.8rem",
    // textAlign: "left",
    color: "#359DB6",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      textAlign: "center",
    },
  },
  avatarStyle: {
    backgroundColor: "#C9E5EC !important",
    [`&.MuiAvatar-root`]: {
      fontSize: "0.8rem",
    },
  },
  select: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",

    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  PortfolioButtons: {
    backgroundColor: "#359DB6",
    textAlign: "center",
    borderRadius: "25px",
    width: "30%",
    fontSize: "0.8rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.4rem",
    },
  },
  multiSelect: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    overflow: "auto",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    // border: "2px solid #1B5563",
    padding: "6px 20px",

    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      overflow: "auto",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
  },
  StockNameAndMenu: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  CollapsedSection: {
    marginTop: "10px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  PriceData: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.6rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  transacitonModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "50vw",
    width: "90vw",
    maxHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  ModalPaper: {
    backgroundColor: "#2a2a2a",
    color: "white",
    width: "80%",
    height: "94%",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  transparentButton: {
    background: "transparent",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  PinSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
    color: localStorage.getItem("dark") ? "#000" : "#fff",
    backgroundColor: localStorage.getItem("dark") ? "#fff" : "#000",
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
    },
  },
  PinModalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "20vw",
    height: "50vh",
    width: "80vw",
    margin: "10% 12%",
    inset: 132,
    background: localStorage.getItem("dark") ? "#ffff" : "#1a1a1a",
    border: localStorage.getItem("dark")
      ? "1px solid #0000"
      : "1px solid #1a1a1a",
    // position: "relative",
    right: 20,
    border: "1px solid green",

    [theme.breakpoints.down("sm")]: {
      right: "0",
      width: "90vw",
      height: "56vh",
    },
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  modalHeading: {
    display: "flex",
    backgroundColor: localStorage.getItem("dark")
      ? "#cdcdcd"
      : "rgb(31, 31, 31)",
    height: "13vh",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  verticalDivider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  PayButton: {
    marginTop: "1rem",
    width: "80%",
    borderRadius: "50px",
    backgroundColor: "#359DB6",
    alignSelf: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      width: "60%",
    },
  },
  otpBox: {
    width: "35px !important",
    height: "35px !important",

    [theme.breakpoints.down("md")]: {
      width: "30px !important",
      height: "30px !important",
    },
  },
  companyInfoButton: {
    backgroundColor: "transparent",
    textAlign: "center",
    // margin: "6px 0px",
    // borderRadius: "25px",
    border: "none",
    width: "100%",
    fontSize: "0.7rem",
    fontWeight: "bold",
    color: "#359DB6",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
      width: "auto",
    },
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  transacitonModalPL: {
    minWidth: "65vw",
    width: "90vw",
    // maxHeight: "100vh",
    // marginLeft: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  ModalPaperPL: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    color: "white",
    width: "80%",
    height: "100%",
    padding: "8%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  ModalGridInner: {
    bgcolor: "grey.900",
    color: "white",
    marginLeft: "13%",
    width: "70%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  innerCard: {
    background: "#202020",
    margin: 8,
    padding: 20,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
}));

const Portfolio = (props) => {
  const {
    stockDetails,
    tab,
    handleStocks,
    stockList,
    data,
    stockDetailsPortfolio,
    singleUserStockList,
    allStockDataCount,
    paginationCB,
    currPage,
    profile,
    stockCategory,
    getAllStocksEngine,
    storeSelectedStockCategory,
    selectedStockCategory,
    onSelect,
    getStockTxnEngineUser,
    stockTransactionDataUser,
    selectedStockId,
    transactionList,
    getInvestmentDetail,
    getPortfolioEngine,
    investmentObj,
  } = props;
  const investmentData = investmentObj?.[stockDetails?.stockId];
  const [currencyType, setCurrencyType] = useState("COG100");
  const [totalAmounts, setTotalAmounts] = useState({});
  const [currentPage, setCurrentPage] = useState(currPage);
  const [selectedStocks, setSelectedStocks] = useState(selectedStockCategory);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectTransaction, setSelectTransaction] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const dataPerPage = 10;
  const totalPage = Math.ceil(allStockDataCount / dataPerPage);
  const [isSelected, setIsSelected] = useState(false);
  const [modelOpen, setModelOpen] = React.useState(false);
  const [security, setSecurity] = React.useState("");
  const [modalData, setModalData] = React.useState(null);
  const [disclamerScreen, setDisclamerScreen] = React.useState(true);
  const [selectedStockIdTransaction, setSelectedStockId] = useState(null);
  const [openPLTransaction, setOpenPLTransaction] = useState(null);

  useEffect(() => {
    if (singleUserStockList) {
      const newTotalAmounts = {};
      singleUserStockList.forEach((item) => {
        const selectedPrice = item.currentPrice.find(
          (price) => price.currencyName === currencyType
        );
        if (selectedPrice) {
          newTotalAmounts[item.stockId] =
            selectedPrice.currentPrice * item.quantity;
        }
      });
      setTotalAmounts(newTotalAmounts);
    }
  }, [singleUserStockList, currencyType]);

  const handlePagData = (val) => {
    paginationCB(dataPerPage, val);
  };

  const viewDetails = (name) => {
    window.open("/stocks/" + name);
  };

  useEffect(() => {
    if (tab == "portfolio" && stockDetails) {
      setSelectedCurrency(stockDetails?.currentPrice[4].currencyName);
    } else {
      return;
    }
  }, []);

  const getCurrentPrice = (stockDetails) => {
    if (stockDetails?.currentPrice !== undefined && tab == "portfolio") {
      const selected = stockDetails?.currentPrice.find(
        (price) => price.currencyName === selectedCurrency
      );
      return selected ? selected.currentPrice : 0;
    } else {
      const priceObj = stockDetails?.stockPrice.find(
        (price) => price.currencyName === currencyType
      );
      let obj = priceObj ? priceObj.currentPrice : "N/A";
      return obj.toString();
    }
  };

  const filterCategory = (data) => {
    storeSelectedStockCategory({ data: data });
  };

  const handleTransactionForStock = (id) => {
    setSelectTransaction(true);
    setSelectedStockId(id);
    getStockTxnEngineUser({
      stockId: id,
      limit: dataPerPage,
      page: currentPage,
    });
  };

  const closeTransactionsModal = (field) => {
    if (field == "PL") {
      setOpenPLTransaction(false);
    } else {
      setSelectTransaction(false);
    }
  };

  const handleSelect = () => {
    setIsSelected(!isSelected);
  };

  let ValueOnHand = 0;
  const getValueOFOnHand = (mp, tp, ts) => {
    return (ValueOnHand = mp * (tp - ts));
  };

  const handleOpenPLTransactions = (id) => {
    setOpenPLTransaction(true);
    getInvestmentDetail({
      stockId: id,
    });
  };

  // let totalProfit = 0;
  const calculateProfit = (
    valueOnHand,
    totalSoldValue,
    totalPurchasedValue
  ) => {
    if ("percent") return valueOnHand + (totalSoldValue - totalPurchasedValue);
  };
  useEffect(() => {
    if (tab == "portfolio") {
      getInvestmentDetail({ stockId: stockDetails?.stockId });
    }
  }, [stockDetails?.stockId]);

  const getMarketPrice = (stockDetails) => {
    const priceObj = stockDetails?.currentPrice.find(
      (price) => price.currencyName === currencyType
    );
    return priceObj ? priceObj.currentPrice : "N/A";
  };

  const calculatePLPercentage = () => {
    if (investmentData) {
      const totalProfit =
        getValueOFOnHand(
          getMarketPrice(stockDetails),
          investmentData?.totalPurchased,
          investmentData?.totalSold
        ) +
        (investmentData?.totalSoldValue -
          investmentData?.totalPurchasedValue * investmentData?.totalPurchased);

      return (
        (totalProfit * 100) /
        (investmentData?.totalPurchasedValue * investmentData?.totalPurchased ||
          1)
      );
    }
    return 0;
  };
  const color = calculatePLPercentage() >= 0 ? "#76CEAB" : "#C49C39";

  const profitColor = color;
  return (
    <>
      <Paper
        elevation={3}
        style={{
          backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
          color: localStorage?.getItem("dark") ? "#000" : "#fff",
          padding: "20px",
          borderRadius: "10px",
          width: "100%",
          margin: "10px 0",
        }}
      >
        <Grid
          container
          spacing={2}
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={2} sm={1} display="flex">
            <div>
              {stockDetails?.stockLogo ? (
                <img
                  src={
                    process.env.REACT_APP_SPACE_KEY +
                    "/" +
                    stockDetails.stockLogo
                  }
                  alt={`logo`}
                  style={{
                    marginRight: "10px",
                    width: "40px",
                    height: "40px",
                  }}
                />
              ) : (
                <Avatar />
              )}
            </div>
          </Grid>
          <Grid
            item
            xs={8}
            sm={2}
            style={{ textAlign: "left" }}
            className={classes.StockNameAndMenu}
          >
            <Grid item xs={11}>
              <Typography
                variant="h6"
                style={{
                  color: "#4cc9f0",
                  marginRight: "10px",
                  overflowWrap: "anywhere",
                }}
              >
                {stockDetails?.stockName}
              </Typography>
            </Grid>
            <Select
              value={selectedCurrency}
              onChange={(e) => {
                setSelectedCurrency(e.target.value);
                if (!isSelected) {
                  setIsSelected(true);
                }
              }}
              style={{ color: "#359DB6", width: "90%" }}
            >
              {stockDetails?.currentPrice.map((price) => (
                <MenuItem key={price.currencyName} value={price.currencyName}>
                  {price.currencyName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={8}
            onClick={handleSelect}
            style={{ textAlign: "right" }}
          >
            {isSelected ? (
              <Grid item xs={12}>
                <Button
                  style={{
                    color: color,
                    background: localStorage.getItem("dark") ? "#fff" : "#000",
                  }}
                  onClick={() =>
                    handleOpenPLTransactions(stockDetails?.stockId)
                  }
                >
                  <span
                    style={{
                      color: localStorage?.getItem("dark") ? "#000" : "#fff",
                    }}
                  >
                    Total P&L:
                  </span>
                  {"  "}&nbsp;
                  {investmentData ? (
                    ` ${formatPrice(calculatePLPercentage().toFixed(2))} %`
                  ) : (
                    <span
                      style={{
                        color: "#76CEAB",
                      }}
                    >
                      0%
                    </span>
                  )}
                </Button>
              </Grid>
            ) : (
              <>
                <Grid xs={12}>
                  <Typography
                    style={{
                      color,
                    }}
                  >
                    {investmentData ? (
                      `${formatPrice(calculatePLPercentage().toFixed(2))} %`
                    ) : (
                      <span
                        style={{
                          color: "#76CEAB",
                        }}
                      >
                        0%
                      </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  &nbsp; &nbsp; €{" "}
                  {formatPrice(
                    stockDetails?.avgInvested * stockDetails?.quantity
                  ) || 0}
                </Grid>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography className={classes.title}>
                    &nbsp; &nbsp; €{" "}
                    {`${formatPrice(getCurrentPrice(stockDetails))}`}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {stockDetails?.quantity || 0}
                </Grid>
                <Grid item xs={2}>
                  <span
                    style={{
                      color: stockDetails?.isBuy ? "#F7A9A0" : "#76CEAB",
                    }}
                  >
                    €{" "}
                    {stockDetails?.quantity *
                      `${formatPrice(getCurrentPrice(stockDetails))}`}
                  </span>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Collapse
          in={isSelected}
          timeout="auto"
          className="CollapseMainContainer"
        >
          <hr />
          <Grid
            item
            container
            xs={12}
            spacing={2}
            className={classes.CollapsedSection}
          >
            <Grid item xs={4} sm={3}>
              <Typography
                style={{ overflowWrap: "anywhere", color: "#A6A6A6" }}
                className={classes.PriceData}
              >
                Invested
                <p style={{ fontWeight: "600" }}>
                  &nbsp; &nbsp; €{" "}
                  {formatPrice(
                    stockDetails?.avgInvested * stockDetails?.quantity
                  ) || 0}
                </p>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={3}>
              <Typography
                variant="body2"
                style={{ color: "#A6A6A6" }}
                className={classes.PriceData}
              >
                Current Price
                <p>
                  &nbsp; &nbsp; €{" "}
                  {`${formatPrice(
                    getCurrentPrice(stockDetails) * stockDetails?.quantity
                  )}`}
                </p>
              </Typography>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Typography
                variant="body2"
                style={{ color: "#A6A6A6" }}
                className={classes.PriceData}
              >
                Stock Units <p> &nbsp; &nbsp; {stockDetails?.quantity}</p>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Typography
                variant="body2"
                style={{ color: "#A6A6A6" }}
                className={classes.PriceData}
              >
                Buy Avg.{" "}
                <p> &nbsp; &nbsp; € {stockDetails?.avgInvested || 0}</p>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Typography
                variant="body2"
                style={{ color: "#A6A6A6" }}
                className={classes.PriceData}
              >
                Current Avg.
                <p>
                  {" "}
                  &nbsp; &nbsp; €{" "}
                  {`${formatPrice(getCurrentPrice(stockDetails))}`}
                </p>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={4}></Grid>
            <Grid
              item
              xs={8}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Button
                variant="contained"
                className={classes.PortfolioButtons}
                onClick={() => handleTransactionForStock(stockDetails?.stockId)}
              >
                Transactions
              </Button>

              <Button
                variant="contained"
                className={classes.PortfolioButtons}
                onClick={() =>
                  handleStocks({
                    stockDetails: stockDetails,
                    currencyType: selectedCurrency,
                  })
                }
              >
                Buy/Sell
              </Button>
              <Button
                variant="contained"
                className={classes.PortfolioButtons}
                onClick={() => viewDetails(stockDetails?.stockName)}
              >
                Firm Profile
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Paper>
      {openPLTransaction && (
        <Modal
          open={"transactions"}
          onClose={closeTransactionsModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{}}
          className={classes.transacitonModalPL}
        >
          <Paper className={classes.ModalPaperPL}>
            <Grid item container>
              {/* <Grid item xs={12} style={{}} className={classes.ModalGridInner}> */}
              <Grid item xs={12}>
                <Card className={classes.ModalGridInner}>
                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: "10px 20px",
                      textAlign: "right",
                      position: "relative",
                      float: "right",
                    }}
                  >
                    <IconButton
                      onClick={() => closeTransactionsModal("PL")}
                      style={{ color: "white" }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </Grid>
                  <CardContent>
                    {/* <Grid item xs={12}> */}
                    {/* </Grid> */}
                    {/* <Grid item xs={10}> */}
                    <Typography variant="h6" gutterBottom>
                      {stockDetails?.stockName}
                    </Typography>
                    {/* </Grid> */}
                    <TableContainer
                      component={Paper}
                      sx={{ bgcolor: "inherit" }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ color: "#A6A6A6" }}>
                              Summary
                            </TableCell>
                            <TableCell align="center" sx={{ color: "#A6A6A6" }}>
                              Value
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell sx={{ color: "white" }}>
                              Total Stocks Purchased
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              {formatPrice(investmentData?.totalPurchased || 0)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: "white" }}>
                              Total Stocks Sold
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              {formatPrice(investmentData?.totalSold || 0)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: "white" }}>
                              Total Stocks on Hand
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              {formatPrice(
                                investmentData?.totalPurchased ||
                                  0 - investmentData?.totalSold ||
                                  0
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: "white" }}>
                              Current Market Price
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              € {formatPrice(getMarketPrice(stockDetails))}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ color: "white" }}>
                              Value of Stock on Hand
                            </TableCell>
                            <TableCell align="center" sx={{ color: "white" }}>
                              €{" "}
                              {formatPrice(
                                getValueOFOnHand(
                                  getMarketPrice(stockDetails),
                                  investmentData?.totalPurchased || 0,
                                  investmentData?.totalSold || 0
                                )
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Card style={{}} className={classes.innerCard}>
                      <TableContainer>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell sx={{ color: "white" }}>
                                Value of Stock on Hand
                              </TableCell>
                              <TableCell style={{ width: "2%" }}></TableCell>
                              <TableCell
                                align="center"
                                style={{ textAlign: "left" }}
                              >
                                €{" "}
                                {formatPrice(
                                  getValueOFOnHand(
                                    getMarketPrice(stockDetails),
                                    investmentData?.totalPurchased || 0,
                                    investmentData?.totalSold || 0
                                  )
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ color: "white" }}>
                                Value of Stock Sold
                                {/* <span style={{ textAlign: "right" }}>
                                    (+)
                                  </span> */}
                              </TableCell>
                              <TableCell style={{ width: "2%" }}>(+)</TableCell>
                              <TableCell
                                align="center"
                                style={{ textAlign: "left" }}
                              >
                                €{" "}
                                {formatPrice(
                                  investmentData?.totalSoldValue || 0
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ color: "white", textAlign: "right" }}
                              ></TableCell>
                              <TableCell style={{ width: "2%" }}>=</TableCell>
                              <TableCell
                                align="center"
                                style={{ textAlign: "left" }}
                              >
                                €{" "}
                                {formatPrice(
                                  ValueOnHand +
                                    investmentData?.totalSoldValue || 0
                                ) || 0}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ color: "white" }}>
                                Cost of Stock Purchased
                              </TableCell>
                              <TableCell style={{ width: "2%" }}>(-)</TableCell>
                              <TableCell
                                align="center"
                                style={{ textAlign: "left" }}
                              >
                                €
                                {formatPrice(
                                  investmentData?.totalPurchasedValue *
                                    investmentData?.totalPurchased || 0
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Total Profit
                                {/* <p
                              </TableCell>
                              <TableCell style={{ width: "2%" }}>(+)</TableCell>
                              <TableCell
                                align="center"
                                style={{ textAlign: "left" }}
                              >
                                €{" "}
                                {formatPrice(
                                  investmentData?.totalSoldValue || 0
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                style={{ color: "white", textAlign: "right" }}
                              ></TableCell>
                              <TableCell style={{ width: "2%" }}>=</TableCell>
                              <TableCell
                                align="center"
                                style={{ textAlign: "left" }}
                              >
                                €{" "}
                                {formatPrice(
                                  ValueOnHand +
                                    investmentData?.totalSoldValue || 0
                                ) || 0}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ color: "white" }}>
                                Cost of Stock Purchased
                              </TableCell>
                              <TableCell style={{ width: "2%" }}>(-)</TableCell>
                              <TableCell
                                align="center"
                                style={{ textAlign: "left" }}
                              >
                                €
                                {formatPrice(
                                  investmentData?.totalPurchasedValue.toFixed() ||
                                    0
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Total Profit
                                {/* <p
                                    style={{
                                      color: "#64A15A",
                                      fontSize: "0.6rem",
                                    }}
                                  >
                                    (Value of Stocks on hand + Value of Stock
                                    sold) - Cost of stock purchased
                                  </p> */}
                              </TableCell>
                              <TableCell style={{ width: "2%" }}></TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  color: profitColor,
                                  textAlign: "left",
                                }}
                              >
                                {`€ ${formatPrice(
                                  calculateProfit(
                                    ValueOnHand,
                                    investmentData?.totalSoldValue || 0,
                                    (investmentData?.totalPurchasedValue || 0) *
                                      investmentData?.totalPurchased || 0
                                  )
                                )} `}
                                <p>{`(${formatPrice(
                                  calculatePLPercentage().toFixed(2)
                                )}% ) `}</p>
                                {/* <p
                                    style={{
                                      color: "#64A15A",
                                      fontSize: "0.6rem",
                                    }}
                                  >
                                    €{" "}
                                    {`(${formatPrice(ValueOnHand)}+
                                     ${formatPrice(
                                       investmentData?.totalSoldValue || 0
                                     )})-
                                      ${formatPrice(
                                        investmentData?.totalPurchasedValue || 0
                                      )}
                                  `}
                                  </p> */}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </CardContent>
                </Card>
              </Grid>
              {/* </Grid> */}
            </Grid>
          </Paper>
        </Modal>
      )}
      {selectTransaction ? (
        <Modal
          open={"transactions"}
          onClose={() => closeTransactionsModal("transaction")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{}}
          className={classes.transacitonModal}
        >
          <Paper style={{}} className={classes.ModalPaper}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "10px 20px",
                }}
              >
                <Grid item xs={12}>
                  {/* <Typography variant="h6">
                    {stockDetails?.stockName}
                  </Typography> */}
                </Grid>

                <IconButton
                  onClick={() => closeTransactionsModal("transaction")}
                  style={{ color: "white" }}
                >
                  <CloseOutlined />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: "0 20px",
                }}
              >
                <StockTransactionList
                  stockId={selectedStockIdTransaction}
                  location="Portfolio"
                />
              </Grid>
            </Grid>
          </Paper>
        </Modal>
      ) : null}{" "}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    singleUserStockList: userReducer.singleUserStock,
    singleUserStockCount: userReducer.singleUserStockCount,
    allStocksEngineList: userReducer.allStocksEngineList,
    allStockDataCount: userReducer.allStockDataCount,
    profile: userReducer.profile,
    stockCategory: userReducer.stockCategory,
    stockCategoryLoading: userReducer.stockCategoryLoading,
    selectedStockCategory: userReducer.selectedStockCategory,
    stockTransactionDataUser: userReducer.stockTransactionData,
    transactionList: userReducer.loadings.transactionList,
    investmentObj: userReducer.investmentData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStockCategory: (data) => dispatch(actionCreator.getStockCategory(data)),
    getInvestmentDetail: (data) =>
      dispatch(actionCreator.getInvestmentDetail(data)),
    getPortfolioEngine: (data) =>
      dispatch(actionCreator.getPortfolioEngine(data)),
    getStockTxnEngineUser: (data) =>
      dispatch(actionCreator.getStockTxnEngine(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
