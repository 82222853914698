import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

//material
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import { Switch } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { Grid, Tooltip } from "@material-ui/core";
import {
  Fade,
  Typography,
  Paper,
  IconButton,
  Backdrop,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

import "./switch.css";
import transaction_icon from "../../asset/images/LeftSideBar/transaction_icon.svg";
import banking_icon from "../../asset/images/LeftSideBar/banking_icon.svg";
import refer_icon from "../../asset/images/LeftSideBar/refer_icon.svg";
import uri_icon from "../../asset/images/Uri/URI_new_icon.svg";
import home_icon from "../../asset/images/LeftSideBar/home_icon.svg";
import profile_icon from "../../asset/images/LeftSideBar/profile_icon.svg";
import business_icon from "../../asset/images/LeftSideBar/business_icon.svg";
import kyc_first from "../../asset/images/Banking/kyc_first.svg";
import * as actionCreator from "../../store/action/index";
import ThemeButton from "../common/button/index";
import Stock_icon from "../../asset/images/LeftSideBar/Stock_Exchange_Icon.svg";
// src\asset\images\LeftSideBar\Stock_Exchange_Icon.svg
import LogoutIcon from "../../asset/images/LeftSideBar/Logout.svg";
import cryptopiaIcon from "../../asset/images/LeftSideBar/cryptopia.svg";

function SwitchThumb(props) {
  return (
    <div className={`switch ${props.isChecked ? "checked" : "unchecked"}`}>
      {props.isChecked ? <NightsStayIcon /> : <WbSunnyIcon />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  centered: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  modal: {
    maxHeight: "90vh",
    minHeight: "50vh",
    width: "55vw",
    display: "flex",
    margin: "auto",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
    [theme.breakpoints.down(1200)]: {
      width: "65vw",
    },
    [theme.breakpoints.down(850)]: {
      width: "80vw",
    },
    [theme.breakpoints.down(600)]: {
      width: "90vw",
      height: "60vh",
      overflow: "scroll",
    },
  },
  paper: {
    width: "100%",
    display: "flex",
    height: "100%",
    overflow: "scroll",
    overflowX: "hidden",
    position: "relative",
    background: localStorage.getItem("dark") ? "#fff" : "#1a1a1a",
    boxShadow: localStorage.getItem("dark")
      ? "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)"
      : "-4px -4px 10px rgba(58, 58, 58, 0.7), 2px 2px 11px 2px rgba(16,16,16,1), 2px 2px 11px 2px rgba(16,16,16,1), -4px -4px 10px rgba(58, 58, 58, 0.7)",
  },
  closeModal: {
    position: "absolute",
    right: 0,
  },

  focusVisible: {},

  leftSidebar: {
    background: "linear-gradient(180deg, #359DB6 14.42%, #054E60 93.38%)",
    borderRadius: 23,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 80,

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      margin: 0,
      padding: 0,
    },
  },
  iconButton: {
    cursor: "pointer",
    width: 50,
    height: 50,
    border: "none",
    borderRadius: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    color: "rgba(255, 255, 255, 0.6)",
    boxShadow: "none",
    "&:hover": {
      background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
      boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
    },
    "&:disabled": {
      cursor: "default",
    },
  },
  iconSelected: {
    cursor: "pointer",
    width: 50,
    height: 50,
    border: "none",
    borderRadius: 14,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(255, 255, 255, 0.6)",
    background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
    boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
  },
  iconDisabled: {
    cursor: "default",
    border: "none",
    borderRadius: 14,
    width: 50,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  },
  iconImg: {
    // width: 30,
    // height: 30,
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  },
  imageContainer: {
    width: "100%",
    height: "60%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down(600)]: {
      height: "40%",
    },
  },
  image: {
    width: "60%",
    height: "auto",
    objectFit: "contain",
  },
}));

const data = [
  {
    name: "home",
    path: "home",
    link: home_icon,
  },
  {
    name: "banking",
    path: "banking",
    link: banking_icon,
  },
  {
    name: "profile",
    path: "profile",
    link: profile_icon,
  },
  {
    name: "business",
    path: "business",
    link: business_icon,
  },

  {
    name: "affiliate program",
    path: "refer",
    link: refer_icon,
  },
  {
    name: "Stock Exchange",
    path: "stocks",
    link: Stock_icon,
  },
  {
    name: "uri",
    path: "uri",
    link: uri_icon,
  },
  {
    name: "Cryptopia Offer",
    path: "cryptopia",
    link: cryptopiaIcon,
  },
];

const LeftSidebar = (props) => {
  const {
    darkMode,
    changeTheme,
    leftSideVisible,
    showLeft,
    kycDone,
    notify,
    logout,
    setEscrowWindow,
    profile,
    getProfile,
    cryptData,
  } = props;
  const [switchOn, setSwitchOn] = useState(darkMode);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(showModal);
  const classes = useStyles();

  const handleChange = () => {
    setSwitchOn((prev) => !prev);
    changeTheme(!switchOn);
  };

  const handleClick = () => {
    leftSideVisible(!showLeft);
    setEscrowWindow(false);
    localStorage.setItem("activeTab", 0);
    localStorage.setItem("activeEscrowTab", "initiate");
  };

  const location = useLocation();

  useEffect(() => {
    if (!profile) {
      getProfile();
    }
  }, [profile]);

  return (
    <Grid container className={classes.leftSidebar} spacing={2}>
      {data.map((item, index) => {
        if (item.name === "uri" && profile?.type === "company") {
          return null;
        }
        if (item.name === "Cryptopia Offer" && cryptData == null) {
          return null;
        }
        return (
          <Grid item key={item.name}>
            <Link to={`/${item.path}`}>
              <Tooltip title={item.name}>
                <button
                  onClick={handleClick}
                  disabled={false}
                  className={
                    new RegExp(`^/${item.path}(/|$)`).test(location.pathname)
                      ? classes.iconSelected
                      : classes.iconButton
                  }
                  variant="contained"
                >
                  <img className={classes.iconImg} src={item.link} />
                </button>
              </Tooltip>
            </Link>
          </Grid>
        );
      })}
      <Grid item key={"logout"} style={{ marginTop: "50px" }}>
        <Tooltip title={"logout"}>
          <button
            onClick={() => logout()}
            disabled={false}
            className={classes.iconButton}
            variant="contained"
          >
            <img className={classes.iconImg} src={LogoutIcon} />
          </button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    logout: () => dispatch(actionCreator.OnLogout()),
    setEscrowWindow: (data) => dispatch(actionCreator.setEscrowWindow(data)),
    getProfile: async () => dispatch(actionCreator.getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSidebar);
