import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import * as actionCreator from "../../store/action/index";

const ChartIcon = (props) => {
  var repeater;
  const { profile, getProfile } = props;

  useEffect(() => {
    if (!profile) {
      getProfile();
    } else {
      repeater = setInterval(SetProfile, 3000);
    }
  }, [profile]);

  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = false;
      s1.src =
        process.env.REACT_APP_ENV === "production"
          ? "https://embed.tawk.to/616f35cef7c0440a591f0b2f/1fid6kcmh"
          : "https://embed.tawk.to/619f8e746bb0760a4944587f/1flbk8uuc";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  const SetProfile = () => {
    if (
      profile &&
      window?.Tawk_API &&
      window?.Tawk_API?.setAttributes &&
      window?.Tawk_Window?.app?.$socket
    ) {
      window.Tawk_API.setAttributes(
        {
          name: profile?.userName,
          email: profile?.email,
        },
        function (error) {}
      );
      clearInterval(repeater);
    }
  };

  return <span style={{ display: "none" }}></span>;
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: () => dispatch(actionCreator.getProfile()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartIcon);
