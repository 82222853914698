import React from "react";
import logolight from "../../asset/images/Header/logo-light.svg";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	buttonContainer: {
		background: "red",
		// width: isMobile ? "40vw" : "20vw",
		fontSize: 18,
		margin: "auto",
		height: 48,
		borderRadius: 8,
		color: "#fff",
		background: "#075062",
		boxShadow: "none",
		marginTop: "1.5rem",
		"&:hover": {
			background: "linear-gradient(154.4deg, #2C94AC 15.73%, #0E6D85 85.25%)",
			boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
		},
	},
}));
const SessionExpired = () => {
	const classes = useStyles();
	return (
		<div style={{ background: "white" }}>
			<Grid item xs={5} style={{ height: "10vh", width: "100vw" }}>
				<img
					src={logolight}
					onClick={() => {
						window.location = "/login";
					}}
					width="80px"
					height="80px"
				/>
			</Grid>
			<Grid
				container
				justifyContent="center"
				direction="column"
				alignItems="center"
				// spacing={1}
				style={{
					width: "100vw",
					height: "90vh",
					padding: "50px",
				}}
			>
				<Grid item>
					<Typography variant="h3" style={{ color: "#ff7300" }}>
						Session Expired!
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" style={{ color: "#000" }}>
						You have been logged out due to inactivity.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="h5" style={{ color: "#000" }}>
						Click on the below button to login again.
					</Typography>
				</Grid>
				<Grid item>
					<Button
						className={classes.buttonContainer}
						// type="submit"
						variant="contained"
						onClick={() => (window.location = "/login")}
					>
						Login
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default SessionExpired;
