import React, { useEffect, useRef, useState } from "react";
import { getWithExpiry } from "../asset/utility";
import { messageService } from "./messageService";
import * as actionCreator from "../store/action/index";
import { paymentReceived } from "./notificationConstructor";
import { connect } from "react-redux";
const SocketConnection = (props) => {
  const {
    notify,
    profile,
    updateStockRealtime,
    notification,
    getOrderBookRealTime,
    updateStockPortfolioRealTime
  } = props;
  const socketRef = useRef(null);
  let getToken = getWithExpiry("token");
  const [storedToken, setStoredToken] = useState();

  if (storedToken !== getToken) {
    setStoredToken(getToken);
  }
  const messageConstructor = (message) => {
    switch (message.action) {
      case "payment_received":
        return paymentReceived(message);
      case "stock_price":
        updateStockRealtime(message?.data);
        return;
      case "order_book":
        getOrderBookRealTime(message?.data);
        return;
      case "stock_portfolio_update":
        updateStockPortfolioRealTime(message?.data);
        return;
      default:
        return message.message;
    }
  };

  const notifier = (message) => {
    let note = messageConstructor(message);
    if (note) {
      notify(note, "success");
    }
  };

  useEffect(() => {
    if (storedToken) {
      let socket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_CONNECTION}${storedToken}`
      );
      socketRef.current = socket;

      if (socket) {
        socket.onopen = () => {};
        socket.onclose = () => {};
        socket.onmessage = async (message) => {
          const data = JSON.parse(message?.data);
          notifier(data);
          // if (data?.action) {
          //   messageService.sendMessage(data);
          // }
        };
        // getNotifications();
      }
    }
    let messageSubscription = messageService.getMessages().subscribe((data) => {
      notifier(data);
    });
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      messageSubscription.unsubscribe();
    };
  }, [storedToken]);
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    notification: userReducer.notification,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (message, varient) =>
      dispatch(actionCreator.notify(message, varient)),
    updateStockRealtime: (data) => {
      dispatch(actionCreator.updateStockRealtime(data));
    },
    getOrderBookRealTime: (data) => {
      dispatch(actionCreator.getOrderBookRealTime(data));
    },
    updateStockPortfolioRealTime: (data) => {
      dispatch(actionCreator.updateStockPortfolioRealTime(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SocketConnection);
