import React, { useEffect, useState } from "react";
import * as actionCreator from "../../../store/action/index";
import { Link } from "react-router-dom";
import userReducer from "../../../store/action/index";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Box,
  FormGroup,
  Checkbox,
  TextField,
  MenuItem,
  FormControl,
  Select,
  useTheme,
  FormControlLabel,
} from "@material-ui/core";
import Spinner from "../../common/Spinner/Spinner";
import BuyStockModal from "./BuyStockModal";
import PaginationComponent from "../../pagination/PaginationComponent";
import PdfViewer from "../../common/pdfViewer/index";
import StockAgreement from "../../../asset/docs/stock_Exchange_Rules.pdf";
import { makeStyles } from "@material-ui/core/styles";
import Portfolio from "../Portfolio";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "55vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: localStorage?.getItem("dark") ? "#fff" : "#000",
    padding: theme.spacing(3, 9),
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  message: {
    color: localStorage?.getItem("dark") ? "#000" : "#fff",
    marginBottom: theme.spacing(2.5),
    fontSize: "18px",
    textAlign: "center",
  },
  button: {
    backgroundColor: "#C49C39",
    color: "#fff",
    textAlign: "center",
    borderRadius: "25px",
    fontWeight: "bold",
    padding: theme.spacing(1, 3),
    marginTop: theme.spacing(2.5),
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#b3862b",
    },
    "&:disabled": {
      backgroundColor: "#d1c3a7",
      color: "#999",
      cursor: "not-allowed",
      opacity: 0.7,
    },
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5%",
    marginRight: "5%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10%",
      marginRight: "10%",
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "20%",
      marginRight: "20%",
    },
  },
  modalContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  formGroup: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  formControlLabel: {
    marginRight: 0,
  },
  link: {
    fontWeight: 700,
    textDecorationLine: "underline",
    color: "#C49C39",
    paddingLeft: 5,
    cursor: "pointer",
    marginLeft: 5,
    marginRight: 5,
  },
}));

const PortfolioList = (props) => {
  const {
    profile,
    singleUserStockList,
    userStockLoading,
    singleUserStockCount,
    portfolioDataCurrentPage,
    getPortfolioEngine,
    createStockAccountEngine,
    handleTabChange,
  } = props;

  const [userPortfolio, setUserPortfolio] = useState([]);
  const [stocks, setStock] = useState();
  const [createStock, setCreateStock] = useState(false);
  const [open, setOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [buyOpen, setBuyOpen] = useState(false);
  const [buyModalData, setBuyModalData] = useState(null);
  const [currentPortfolioPage, setCurrentPortfolioPage] = useState(
    portfolioDataCurrentPage
  );
  const [selectedStockId, setSelectedStockId] = useState(null);

  const classes = useStyles();

  const dataPerPage = 10;
  const totalPortfolioPage = Math.ceil(singleUserStockCount / dataPerPage);

  const handleStocks = ({ stockDetails, currencyType }) => {
    setOpen(!open);
    if (stockDetails) {
      setBuyModalData({ stockDetails, currencyType });
    } else {
      setBuyModalData(null);
    }
  };

  // handleCreateAccount
  const handleCreateAccount = () => {
    createStockAccountEngine({
      acceptedEngineTerms: checked,
      callBack: handleTabChange,
    });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleOpen = () => {
    setTermsModalOpen(true);
  };

  const handleClose = () => {
    setTermsModalOpen(false);
  };

  const handlePaginationPortfolioData = (val) => {
    getPortfolioEngine({
      limit: dataPerPage,
      page: val,
    });
  };

  const handleSelectStock = (stockId) => {
    setSelectedStockId((prevId) => (prevId === stockId ? null : stockId));
  };

  const handleCloseModal = () => {
    setOpen(false);
    setBuyOpen(false);
  };
  const handleBuyStocks = ({ stockDetails, currencyType, title }) => {
    setBuyOpen(!buyOpen);
    if (stockDetails) {
      setBuyModalData({ stockDetails, currencyType, title });
    } else {
      setBuyModalData(null);
    }
  };
  const handleState = () => {
    setCreateStock(false);
  };

  return (
    <>
      {!open ? (
        <Grid container item xs={12} direction="column" spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {profile?.status?.is_cogito_stocks_profile &&
            Array.isArray(singleUserStockList) &&
            singleUserStockList.length > 0 ? (
              singleUserStockList.map((stockDetails, index) => (
                <Portfolio
                  key={stockDetails?.stockId}
                  stockDetails={stockDetails}
                  tab={"portfolio"}
                  paginationPortfolioCB={handlePaginationPortfolioData}
                  currPortfolioPage={portfolioDataCurrentPage}
                  handleStocks={handleStocks}
                  onSelect={handleSelectStock}
                  isSelected={selectedStockId === stockDetails.stockId}
                />
              ))
            ) : (
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginTop: "8%" }}
              >
                {profile?.status?.is_cogito_stocks_profile ? (
                  <Grid
                    item
                    container
                    xs={12}
                    style={{
                      padding: "10px",
                      boxShadow: localStorage?.getItem("dark")
                        ? "-4px -4px 10px rgba(58, 58, 58, 0.7)"
                        : "none",
                      backgroundColor: localStorage?.getItem("dark")
                        ? "#fff"
                        : "#000",
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ padding: "40px" }}>
                      No Stocks listed
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Box className={classes.container}>
                      <Typography variant="h6" className={classes.message}>
                        It looks like you don't have a Stock account yet. Create
                        one now and take the first step towards securing your
                        financial future.
                      </Typography>

                      <div
                        className={classes.formGroup}
                        style={{ display: "flex", alignItems: "flex-start" }}
                      >
                        <input
                          type="checkbox"
                          onChange={handleChange}
                          checked={checked}
                          style={{
                            color: "#C49C39",
                            marginRight: "8px",
                            width: "20px",
                            height: "20px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          aria-label="checkbox with default color"
                        />
                        <span className={classes.message}>
                          I agree to the{" "}
                          <a
                            onClick={handleOpen}
                            className={classes.link}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            stock exchange rules
                          </a>{" "}
                          and terms of service ensuring a secure and regulated
                          trading environment.
                        </span>
                      </div>

                      <Button
                        id={`create-stock-account`}
                        className={classes.button}
                        disabled={!checked}
                        onClick={handleCreateAccount}
                      >
                        Create Stock account
                      </Button>

                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={termsModalOpen}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                        className={classes.modal}
                      >
                        <Fade in={termsModalOpen}>
                          <Grid container className={classes.modalContent}>
                            <PdfViewer file={StockAgreement} />
                          </Grid>
                        </Fade>
                      </Modal>
                    </Box>
                  </>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {profile?.status?.is_cogito_stocks_profile &&
            Array.isArray(singleUserStockList) &&
            singleUserStockList.length > 0 ? (
              <Box
                display="flex"
                direction="row"
                justifyContent="center"
                // style={{ marginTop: "1rem" }}
              >
                <PaginationComponent
                  page={currentPortfolioPage + 1}
                  pageCount={totalPortfolioPage}
                  onChange={(e, v) => {
                    setCurrentPortfolioPage(v - 1);
                    handlePaginationPortfolioData(v - 1);
                  }}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      ) : open || buyOpen ? (
        <BuyStockModal
          open={open}
          buyOpen={buyOpen}
          stockDetails={buyModalData?.stockDetails}
          currType={buyModalData?.currencyType}
          createStock={createStock}
          handleClose={handleCloseModal}
          title={buyModalData?.title}
          handleState={handleState}
          minQuantity={buyModalData?.minQuantity}
          handleStocks={handleStocks}
          handleBuyStocks={handleBuyStocks}
          getPortfolioEngine={getPortfolioEngine}
          tab={"portfolio"}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    profile: userReducer.profile,
    stockList: userReducer.stockList,
    allStocksEngineList: userReducer.allStocksEngineList,
    userStocks: userReducer.userStocks,
    userStocksTransations: userReducer.userStocksTransations,
    userStockTransactionCount: userReducer.userStockTransactionCount,
    stockAccount: userReducer.loadings.stockAccount,
    stocksOwned: userReducer.loadings.stocksOwned,
    stockListLoading: userReducer.loadings.stockList,
    stockTransactionLoading: userReducer.loadings.stockTransaction,
    allStockEngineList: userReducer.allStockEngineList,
    singleUserStockList: userReducer.singleUserStock,
    singleUserStockCount: userReducer.singleUserStockCount,
    userStockLoading: userReducer.loadings.userStock,
    allStockDataCurrentPage: userReducer.allStockDataCurrentPage,
    portfolioDataCurrentPage: userReducer.portfolioDataCurrentPage,
    selectedStockCategory: userReducer.selectedStockCategory,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createStockAccountEngine: (callBack) => {
      dispatch(actionCreator.createStockAccountEngine(callBack));
    },
    getAllStocksEngine: (data) =>
      dispatch(actionCreator.getAllStocksEngine(data)),
    getPortfolioEngine: (data) =>
      dispatch(actionCreator.getPortfolioEngine(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PortfolioList);
